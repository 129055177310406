.quote {
	background-color: $navy;
	color: $white;

	&__blockquote {
		background-color: $teal;
		padding: 30px;
		margin: 0;
	}

	&__left {
		display: flex;
		flex-direction: column;
		position: static;
		transition: opacity 0.3 ease-in-out;
		opacity: 0;

		&.animate {
			opacity: 1;
		}
	}

	&__content {
		font-size: 3em;
		font-family: $kortoBold;
		font-weight: 400;
		padding-bottom: 30px;

		&:before {
			content: '';
			display: block;
			width: 55px;
			height: 47px;
			background: url('/img/rebrand/quote/quote-mark.svg') no-repeat 0 0 / contain;
		}
	}

	&__cite {
		font-style: normal;
		text-align: center;
	}

	&__image {
		width: 100%;
		border-radius: 50%;
		max-width: 240px;
	}

	&__cite-content {
		display: block;
		font-size: 2.3em;
		padding-top: 30px;
	}

	&__person {
		font-size: 1.391em;
		font-family: $kortoBold;
		font-weight: 400;
	}

	&__icon-outer {
		display: none;
	}

}


/* BREAKPOINTS */

@media only screen and (min-width: map-get($bp, '560')) {

	.quote {

		&__blockquote {
			padding: 50px;
		}

	}

}

@media only screen and (min-width: map-get($bp, '720')) {

	.quote {

		&__blockquote {
			margin: 0;
		}

		&__left {
			flex-direction: row;
		}

		&__content {
			flex: 1;
			order: 1;
		}

		&__cite {
			order: 0;
			padding-right: 60px;
		}

	}

}

@media only screen and (min-width: map-get($bp, '1024')) {

	.quote {
		display: flex;
		flex-direction: row;
		align-items: center;

		&__blockquote {
			margin: 0;
			width: 65%;
			box-sizing: border-box;
			background: url('/img/rebrand/quote/bg-shape.svg') no-repeat right center / auto 100%;
		}

		&__content {
			font-size: 2.5em;
			line-height: 1.45em;
		}

		&__cite {
			text-align: left;
		}

		&__image {
			max-width: 205px;
		}

		&__cite-content {
			font-size: 1.7em;
		}

		&__icon-outer {
			display: block;
			flex: 1;
			padding: 0 6%;
		}

		&__icon {
			width: 100%;
			margin-top: -14%;
		}

	}

}

@media only screen and (min-width: map-get($bp, '1280')) {

	.quote {
		overflow: hidden;

		&__blockquote {
			margin: -1px 0;
			padding: 4.5% 4% 7% 16%;
		}

		&__content {
			font-size: 1.8em;


			&:before {
				width: 35px;
				height: 32px;
			}
		}

		&__cite {
			text-align: left;
			width: 58.5%;
			box-sizing: border-box;
			max-width: 449px;
		}

		&__image {
			max-width: 100%;
		}

		&__cite-content {
			padding-top: 20px;
		}

	}

}

@media only screen and (min-width: map-get($bp, '1600')) {

	.quote {

		&__content {
			font-size: 2.1em;

			&:before {
				width: 47px;
				height: 40px;
			}
		}

		&__cite-content {
			font-size: 2.1em;
		}

	}

}

@media only screen and (min-width: map-get($bp, '1920')) {

	.quote {

		&__content {

			&:before {
				width: 60px;
				height: 50px;
			}
		}

		&__cite-content {
			font-size: 2.3em;
		}

	}

}
