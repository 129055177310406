/* ORIGINAL STYLE OVERRIDES */

.expectations {

	.wrapper {

		&.one {

			.content {
				display: flex;
				align-items: center;

				&.one {
					display: block;

					.title {
						color: $navy;

						&.one {
							font-size: 5em;
							line-height: 1.2em;
							color: $navy;
							margin-bottom: 1.1em;
						}

						&.two {
							font-size: 5em;
							color: $teal;
						}
					}
				}

				&.two {

					.col {

						&.image {

							.image {
								width: 82%;
							}
						}
					}

					.title {

						&.two {
							margin-bottom: 0;
						}
					}
				}

				&.three {

					.title {

						&.one {
							margin-bottom: 0;
						}
					}

					.col {

						&.image {

							.image {
								width: 72%;
							}
						}
					}
				}

				&.four {

					.col {

						&.image {

							.image {
								width: 82%;
							}
						}
					}

					.title {

						&.one {
							margin-bottom: 0;
						}
					}
				}
			}

			.text {

				.title {
					color: $navy;
				}

				.regular{
					color: $navy;
					margin-top: 1em;
					display: block;
				}
			}
		}
	}
}

/* BREAKPOINTS */

@media only screen and (max-width: map-get($bp, '919')) {

	.expectations {

		.wrapper {

			&.one {
				font-size: .75em;
				padding: 0 6em;
				width: 100%;
				box-sizing: border-box;

			}

		}

	}
}