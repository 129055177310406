/* ORIGINAL STYLE OVERRIDES */

.future {

	.wrapper {
		background-color: $navy;

		> .image {

			&.zoomout {
				right: 177px;
				top: -238px;
				width: 1035px;
			}
		}
	}

	.map-image {
		display: block;
		width: 100%;
	}

	.title {
		color: $teal;
	}

	.content {
		top: 15.2em;
		width: 59.5em;

		&.animate {
			left: 7.5%;
		}
	}

	.main {
		padding-right: 10em;
		margin-bottom: 1.65em;

		.text {
			margin-bottom: 0;
		}
	}

	.legend {
		border: none;
		padding: 1.5em 9.5em 1.4em 0em;
		border-radius: 0 128px 128px 0;
		position: relative;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			right: 99%;
			width: 100%;
			background-color: $white;
		}

		.row {
			display: flex;

			justify-content: space-between;
			margin: 1.7em 0;
		}

		.item {
			display: flex;
			align-items: center;
			width: 48%;

			.text {
				width: auto;
				padding: 0 0 0 .4em;
			}
		}

		.symbol,
		.text {
			display: block;
		}

	}

	.junctions {
		top: 0;
		background: url('/img/rebrand/future/steps-border.svg') no-repeat 0 0 / contain;
		border: none;
		border-top: none;
		color: $white;
		padding: 7.9em 2.4em 7.2em 1.6em;
		width: 26em;

		.row {
			margin-top: 2em;
			position: relative;
		}

		.image {
			position: absolute;
			left: 5px;
			top: 50%;
			transform: translateY(-50%);
			width: auto;
		}

		.title {
			color: $teal;
			margin-bottom: 1.4em;
			margin-left: .5em;
		}

		.text {
			color: $white;
			padding: 0 1em 0 3.2em;
			font-size: 1.1em;
		}

		&.animate {
			right: 2.125%;
		}
	}
}

/* BREAKPOINTS */

@media only screen and (max-width: map-get($bp, '1599')) {

	.future {

		.wrapper {

			> .image {

				&.zoomout {
					right: 60px;
				}
			}
		}

		.main {
			background-color: rgba($black, .75);
			padding: 4em 5em 5em;
			border-radius: 30px 30px 0 0;
			margin-bottom: 0;
		}

		.legend {
			border-radius: 0 0 30px 30px;
			padding-left: 3.5em;
			padding-right: 3.5em;

			&:before {
				display: none;

			}
		}
	}
}

@media only screen and (max-width: map-get($bp, '1279')) {

	.future {

		.wrapper {

			> .image {

				&.zoomout {
					right: -230px;
				}
			}
		}

		.junctions {

			.image {
				width: 15px;
			}
		}
	}
}

@media only screen and (max-width: map-get($bp, '719')) {

	.future {

		.wrapper {
			display: flex;
			flex-direction: column;
			height: auto;

			> .image {
				position: initial;
				margin-left: 0em;
				margin-bottom: 0%;
				width: 100% !important;
				margin-top: 0em !important;
				order: 1;
			}
		}

		.content {
			order: 0;
			width: 100%;
			height: auto;
		}

		.junctions {
			order: 2;
			width: 100%;
			opacity: 1;
			background: none;
			font-size: 1.5em;
			padding: 3em;
			display: flex;
			flex-wrap: wrap;

			.title {
				margin-bottom: 0;
			}

			.image {
				width: auto;
				top: 4px;
				transform: none;
			}

			.row {
				width: 50%
			}
		}

		.main {
			border-radius: 0;
			background-color: transparent;
			min-height: 0;
			margin-bottom: 0;
		}

		.legend {
			padding: 3em;
			border-radius: 0;
			min-height: 0;
		}

		.junctions,
		.main,
		.legend,
		.content {
			position: static;
		}
	}

}