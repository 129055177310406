.delivery {
	background: url("/img/rebrand/delivery/outlines_1.png") no-repeat left bottom / 120px auto, 	url("/img/rebrand/delivery/outlines_2.png") no-repeat right -25px bottom / 120px auto;
	font-size: 3.000em;
	font-family: $kortoBold;
	font-weight: 400;
	padding: 60px 60px 180px;
	color: $navy;
	text-align: center;

	&__inner {
		max-width: 665px;
		margin: 0 auto;
	}

	&__text {
		margin: 0;
	}
}

/* BREAKPOINTS */

@media only screen and (min-width: map-get($bp, '920')) {

	.delivery {
		padding: 60px 0;
	}

}

@media only screen and (min-width: map-get($bp, '1280')) {

	.delivery {
		background-position: left bottom, right -10px bottom;
		background-size: 200px auto, 180px auto;

		&__inner {
			max-width: 745px;
		}
	}

}

@media only screen and (min-width: map-get($bp, '1600')) {

	.delivery {
		background-position: left bottom, right 27px bottom;
		background-size: 346px auto, 300px auto;
		padding: 126px 0;

		&__inner {
			max-width: 845px;
		}
	}

}

@media only screen and (min-width: map-get($bp, '1920')) {

	.delivery {
		background-size: 386px auto, 325px auto;

		&__inner {
			max-width: 945px;
		}
	}

}