.footer {

	.wrapper {
		display: flex;
		flex-wrap: wrap;
	}

	.link {
		color: $teal;

		&:hover,
		&:focus {
			color: $white;
		}
	}

	&__utility {

		.link {
			color: $white;

			&:hover,
			&:focus {
				color: $teal;
			}
		}
	}
}