/* ORIGINAL STYLE OVERRIDES */

.transforming {

	.wrapper {

		&.six {
			background-color: $teal;

			.content {
				color: $navy;
			}
		}
	}
}

@media only screen and (max-width: map-get($bp, '719')) {

	.transforming {

		.wrapper {

			&.six {
				padding-left: 3em;
				padding-right: 3em;
			}
		}
	}

}