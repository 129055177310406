/* ORIGINAL STYLE OVERRIDES */

.main-nav {
	max-width: none;

	.list {
		max-width: 1200px;
		margin: 0 auto;

		.item {

			&.home {
				display: flex;
				height: 56px;
				justify-content: center;
				align-items: center;

				.link {
					display: block;
					padding: 0;
					line-height: initial;

					.image {

					}
				}

			}

			.link {
				color: $navy;
				font-weight: normal;
				font-family: $kortoBold;
				padding: 0;
			}

		}

	}

	.menu-icon {

		.bar1,
		.bar2,
		.bar3 {
			background-color: $navy;
		}
	}

}


/* BREAKPOINTS */

@media only screen and (min-width: map-get($bp, '1280')) {

	.main-nav {

		.list {

			.item {

				&.home {
					height: 64px;
				}

			}

		}

	}

}

@media only screen and (min-width: map-get($bp, '1600')) {

	.main-nav {

		.list {

			.item {

				&.home {
					height: 72px;
				}

			}

		}

	}

}

@media only screen and (min-width: map-get($bp, '1920')) {

	.main-nav {

		.list {

			.item {

				&.home {
					height: 80px;
				}

			}

		}

	}

}