/* ORIGINAL STYLE OVERRIDES */

.intro {

	.wrapper {

		&.three {
			background-color: $teal;

			.content {
				color: $navy;

				h2 {
					margin-bottom: 1.2em;
				}
			}

			.col {

				.title {
					letter-spacing: -.01em;
				}

				&.three {

					.image {

						img {
							width: auto;
							max-width: 70%;
						}

					}
				}
			}
		}
	}
}


/* BREAKPOINTS */

@media only screen and (min-width: map-get($bp, '1600')) {

	.intro {

		.wrapper {

			&.three {

				.content {
					padding: 11em 0 8em;

					h2 {
						font-size: 5.556em;
					}
				}

				.col {
					max-width: none;
					width: 48em;

					.info {
						padding-top: 1.5em;
					}

				}
			}
		}
	}

}

@media only screen and (min-width: map-get($bp, '1920')) {

	.intro {

		.wrapper {

			&.three {

				.content {

					h2 {
						font-size: 5em;
					}
				}

			}

		}

	}

}