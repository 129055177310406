.banner {
	position: relative;
	color: $white;
}

.banner__content {
	background: url('../img/rebrand/banner/banner-shape.png') no-repeat right top / cover;
	position: absolute;
	left: 0;
	right: 10%;
	top: 0;
	bottom: 0;
	padding: 9% 11.5% 0 9.5%;
}

.banner__title {
	font-size: 7.7vw;
	font-family: $kortoBold;
	margin: 0 0 10px;
	font-weight: 400;
}

.banner__text {
	font-size: 3.7vw;
	line-height: 1.7em;
}

.banner__image img {
	width: 100%;
}


/* BREAKPOINTS */

@media only screen and (min-width: map-get($bp, '720')) {

	.banner__content {
		right: 48.3%;
		padding: 7% 7% 0 7%;
	}

	.banner__title {
		font-size: 3.7vw;
	}

	.banner__text {
		font-size: 1.6vw;
	}
}

@media only screen and (min-width: map-get($bp, '1920')) {

	.banner__content {
		right: 48.3%;
	}

	.banner__title {
		font-size: 70px;
	}

	.banner__text {
		font-size: 30px;
	}
}