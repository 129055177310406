// Colors
$navy: #0D0F3D;
$teal: #00dec6;
$pink: #e50080;
$purple: #7317de;
$black: #000;
$white: #fff;
$green: #A0CA2A;

// Breakpoints
$bp: (
"559" : 559px,
"560" : 560px,
"719" : 719px,
"720" : 720px,
"919" : 919px,
"920" : 920px,
"1024" : 1024px,
"1279" : 1279px,
"1280" : 1280px,
"1599" : 1599px,
"1600" : 1600px,
"1919" : 1919px,
"1920" : 1920px,
);

// Fonts
$kortoBook: "Korto Book", Arial, Helvetica, sans-serif;
$kortoBold: "Korto Bold", Arial, Helvetica, sans-serif;
