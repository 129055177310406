.the-pic-today {

	&__title {
		font-size: 5em;
		color: $navy;
		font-family: $kortoBold;
		margin: 13px 0 20px;
		font-weight: 400;
	}

	&__text {
		font-size: 3em;
		margin: 0;
		line-height: 1.45em;
	}
}

/* ORIGINAL STYLE OVERRIDES */

.transforming {

	.wrapper {

		&.two {
			display: flex;
			justify-content: center;
			align-items: flex-start;
			padding: 16em 0;

			.animation {
				position: static;
				margin: 0 3em;
			}

			.content {

				&.one {
					box-shadow: 1em 1em $teal;
					transform: translateX(-130%);

					&.animate {
						transform: translateX(0);
					}
				}

				&.two {
					transform: translateX(130%);

					&.animate {
						transform: translateX(0);
					}
				}
			}
		}

		&.three {
			display: flex;
			justify-content: center;
			padding: 0 6em 16em;

			.animation {
				position: static;
				margin: 0 3em;
			}

			.content {
				text-align: left;

				&.one {
					color: $teal;
					margin-right: 0;

					.text,
					.title {
						color: $teal;
					}

					.text {

						.title {
							color: $teal;
						}
					}
				}

				&.two {
					color: $navy;
					margin-right: 0;

					.text,
					.title {
						color: $navy;
					}

					.text {

						.title {
							color: $navy;
						}
					}
				}

				.text {

					.title {
						display: block;
						margin-bottom: .275em;
					}
				}

				> .title {
					font-size: 4em;
					line-height: 1.175em;
				}
			}
		}

		&.four {
			padding-bottom: 2.4em;

			.content {
				margin-bottom: 15em;
				position: relative;

				&.one {

					.track {
						width: 50%;

						.stop {

							&.one {
								left: 35%;
							}

							&.two {
								right: 0;
							}
						}

					}
				}

				&.two {

					.track {
						width: 84%;

						.stop {

							&.one{
								left: 21%;
							}

							&.two {
								right: 0;
							}

						}
					}
				}

				&.three {
					text-align: center;
					color: $navy;
					font-family: $kortoBold;
					font-size: 3em;
					max-width: 40em;
					margin: 0 auto;
					padding: 0 3em 3em;

					.text {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}

.track {
	padding-top: 5em;

	.line {
		background-color: $navy;
	}

	.stop {
		background-color: #fff;

		.dot {
			background-color: $teal;
		}

		.text {
			color: $navy;
			text-transform: initial;
		}

	}

}

/* BREAKPOINTS */

@media only screen and (max-width: map-get($bp, '1600')) {

	.the-pic-today {

		&__title {
			font-size: 4em;
		}

		&__text {
			font-size: 2.5em;
		}
	}

}

@media only screen and (min-width: map-get($bp, '1280')) {

	.transforming {

		.wrapper {

			&.two {

				.content {

					&.two {
						width: 59.5em;
					}
				}
			}
		}
	}

}

@media only screen and (max-width: map-get($bp, '1279')) {

	.transforming {

		.wrapper {

			&.two {
				padding: 12em 0;
			}

			&.three {
				padding-bottom: 12em;
			}
		}
	}

}

@media only screen and (max-width: map-get($bp, '919')) {

	.transforming {

		.wrapper {

			&.two {
				padding: 6em 0;
			}

			&.three {
				padding-bottom: 6em;

				.content {
					text-align: center;

					.text {

						.title {
							font-size: 2.5em;
						}
					}
				}
			}
		}
	}

}

@media only screen and (max-width: map-get($bp, '719')) {

	.transforming {

		.wrapper {


			.content {
				margin-bottom: 3em;
			}

			&.two {
				display: block;
				padding-bottom: 6em;
			}

			&.three {
				display: block;

				.content {
					margin-bottom: 3em;

					&.two {
						margin-left: 0;
					}
				}
			}

			&.four {
				//padding-bottom: 22.4em;
			}
		}
	}

}

@media only screen and (max-width: map-get($bp, '559')) {

	.transforming {

		.wrapper {

			&.four {

				.content {

					&.one {

						.track {
							width: 60%;
						}
					}
				}
			}
		}
	}
}