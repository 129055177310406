.benefits__title {
	color: $navy;
	font-size: 4em;
	line-height: 1.125em;
	text-align: center;
	font-family: $kortoBold;
	font-weight: normal;
	margin: .5em 0 .5em 0;
}

.benefits__col--2 {
	padding: 0 6% 0 6%;
}

.benefits__subtitle {
	color: $navy;
	font-size: 3em;
	line-height: 1.125em;
	text-align: center;
	font-family: $kortoBold;
	font-weight: 400;
}

.benefits__video {
	width: 100%;
	position: relative;
}

.benefits__video-elm {
	display: block;
	width: 100%;
}

/* ORIGINAL STYLE OVERRIDES */

.benefits {

	.wrapper {

		&.one {

			.blocks {
				display: flex;
				flex-wrap: wrap;
				width: auto;
			}

			.block {
				position: static;
				left: 0;
				transform: translateX(-230%);

				&.one {
					background-color: $teal;
					color: $navy;
				}

				&.two {
					background-color: $purple;
					color: $white;
				}

				&.three {
					background-color: $green;
					color: $navy;
				}

				&.four {
					background-color: #E9E623;
					color: $navy;
				}

				&.five {
					background-color: #1F3529;
					color: $white;
				}

				&.six {
					background-color: $pink;
					color: $white;
				}
			}

			.animate {
				transform: translateX(0);
			}
		}
	}
}

/* BREAKPOINTS */

@media only screen and (min-width: map-get($bp, '920')) {

	.benefits__wrapper {
		display: flex;
	}

	.benefits__title {
		text-align: left;
		margin-left: 5.9%;
	}

	.benefits__col--1 {
		width: 47.5%;
		padding-left: 5.9%;
	}

	.benefits__col--2 {
		display: flex;
		align-items: center;
		width: 52.5%;
		padding: 0 5.9% 0 4.6%;
	}

	.benefits__subtitle {
		margin-top: 0;
	}

	.benefits {

		.wrapper {

			&.one {
				padding-bottom: 0;
			}
		}
	}
}

@media only screen and (min-width: map-get($bp, '1280')) {

	.benefits {

		.wrapper {

			&.one {
				padding-bottom: 30px;
			}
		}
	}

}

@media only screen and (min-width: map-get($bp, '1600')) {

	.benefits__title {
		font-size: 5.556em;
	}

	.benefits {

		.wrapper {

			&.one {
				padding-bottom: 56px;
			}
		}
	}
 
}

@media only screen and (min-width: map-get($bp, '1920')) {

	.benefits__title {
		font-size: 5em;
	}
}