/* ORIGINAL STYLE OVERRIDES */

.intro {

	.wrapper {

		&.two {

			.title {

				span {

					&.one {
						color: $navy;
					}

					&.two {
						font-family: $kortoBook;
						font-weight: normal;
						color: $navy;
						max-width: 525px;
						margin: 0 auto;
					}
				}
			}
		}
	}
}

/* BREAKPOINTS */

@media only screen and (min-width: map-get($bp, '1600')) {

	.intro {

		.wrapper {

			&.two {

				.content {
					width: 80em;
				}

				.title {

					span {

						&.one {
							font-size: 5.556em;
						}

					}

				}

			}

		}

	}
}

@media only screen and (min-width: map-get($bp, '1920')) {

	.intro {

		.wrapper {

			&.two {

				.title {

					span {

						&.one {
							font-size: 5em;
						}

					}

				}

			}

		}

	}

}