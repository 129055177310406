.banner-alt {
	font-size: 2em;
	font-family: $kortoBold;
	color: $navy;
	line-height: 1.3em;
	font-weight: 400;

	&__wrapper {
		position: relative;
	}

	&__content {
		position: absolute;
		bottom: 0;
		top: 0;
		left: 0;
		padding: 60px 18px 30px 45px;
		max-width: 290px;
		box-sizing: border-box;
		background: url('/img/rebrand/transforming/background-shape.svg') no-repeat 0 0/100% auto;}

	&__title {
		font-size: .75em;
		margin: 0 49px 5px 0;
		font-weight: 400;
	}

	&__text {
		margin: 0;
		font-weight: 100;
		font-size: .675em;
		font-family: $kortoBook;
	}

	&__img {
		display: block;
		width: 100%;
	}

}

/* BREAKPOINTS */

@media only screen and (min-width: map-get($bp, '560')) {

	.banner-alt {
		font-size: 3em;

		&__content {
			padding: 120px 40px 0 90px;
			max-width: 474px;
		}

		&__title {
			font-size: 1em;
			margin-bottom: 10px;
		}

		&__text {
			font-size: .875em;
		}

	}

}

@media only screen and (min-width: map-get($bp, '920')) {

	.banner-alt {
		font-size: 2.31em;

		&__content {
			padding: 155px 60px 0 85px;
			line-height: 1.482em;
			max-width: 455px;
		}

		&__title {
			margin-right: 0px;

			.newcastle & {
				margin-top: -30px;
			}
		}

	}

}

@media only screen and (min-width: map-get($bp, '1280')) {

	.banner-alt {
		font-size: 2.5em;

		&__content {
			padding: 194px 90px 0 107px;
			line-height: 1.482em;
			max-width: 570px;

		}

	}

}

@media only screen and (min-width: map-get($bp, '1600')) {

	.banner-alt {
		font-size: 2.75em;

		&__content {
			padding: 245px 106px 0 136px;
			line-height: 1.482em;
			max-width: 710px;
		}

	}

}

@media only screen and (min-width: map-get($bp, '1920')) {

	.banner-alt {
		font-size: 3em;

		&__content {
			padding: 295px 110px 0 162px;
			line-height: 1.44em;
			max-width: 855px;

		}

	}

}
