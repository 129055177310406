@keyframes pulse-dot {

	0% {
		-ms-transform: scale(0.8);
		-webkit-transform: scale(0.8);
		transform: scale(0.8);
	}

	50% {
		-ms-transform: scale(1);
		-webkit-transform: scale(1);
		transform: scale(1);
	}

	100% {
		-ms-transform: scale(0.8);
		-webkit-transform: scale(0.8);
		transform: scale(0.8);
	}
}

@font-face {
	font-family: "Korto Book";
	src: url("/fonts/Talbot Type - Korto-Book.otf") format("opentype");
	font-weight: 300;
}

@font-face {
	font-family: "Korto Bold";
	src: url("/fonts/Talbot Type - Korto-Bold.otf") format("opentype");
	font-weight: 300;
}

body {
	font-family: "Korto Book", Arial, Helvetica, sans-serif;
	font-size: 10px;
}

html,
body {
	overflow-x: hidden;
}

body {
	margin: 0;
}

@media only screen and (max-width: 1919px) {

	body {
		font-size: 9px;
	}
}

@media only screen and (max-width: 1599px) {

	body {
		font-size: 8px;
	}
}

@media only screen and (max-width: 1279px) {

	body {
		font-size: 7px;
	}
}

.container {
	margin: 8em auto 0;
	max-width: 1920px;
}

.wrapper {
	position: relative;
	max-width: 100%;
}

.wrapper.bg {
	background-repeat: no-repeat;
	background-size: contain;
	height: 0;
}

.wrapper.bg .content {
	position: absolute;
}

.title {
	font-family: "Korto Bold", Arial, Helvetica, sans-serif;
	font-weight: normal;
	margin: 0;
}

.text {
	margin: 0;
}

.image {
	display: block;
	max-width: 100%;
}

.content {
	max-width: 100%;
}

@media only screen and (max-width: 919px) {

	.brick-container {
		position: absolute;
		top: -100px;
		right: 0;
	}
}

@media only screen and (max-width: 719px) {

	.brick-container {
		top: -200px;
		opacity: 0.75;
	}
}

@media only screen and (max-width: 559px) {

	.brick-container {
		top: -300px;
		opacity: 0.5;
	}
}

.brick {
	display: inline-block;
	height: 20em;
	position: absolute;
	width: 20em;
}

@media only screen and (max-width: 1599px) {

	.brick {
		font-size: 0.8em;
	}
}

@media only screen and (max-width: 1279px) {

	.brick {
		font-size: 0.7em;
	}
}

.brick.doublew {
	width: 40em;
}

.brick.doubleh {
	height: 40em;
}

.brick.mustard {
	background-color: #c2cf32;
}

.brick.white {
	background-color: #fff;
}

.brick.grey {
	background-color: #e5e4df;
}

.brick.green {
	background-color: #64b554;
}

.brick.yellow {
	background-color: #e8e14b;
}

.brick.magenta {
	background-color: #d75790;
}

.brick.purple {
	background-color: #48418e;
}

.brick.maroon {
	background-color: #7c2d79;
}

.brick.turquoise {
	background-color: #00b6bf;
}

.track {
	padding-top: 5em;
}

.track .line {
	background-color: #48418e;
	display: inline-block;
	height: 0.6em;
	position: relative;
	vertical-align: middle;
	width: 100%;
}

.track .stop {
	background-color: #fff;
	display: inline-block;
	padding: 0 0.9em;
	position: absolute;
	top: -0.7em;
}

.track .stop .dot {
	background-color: #d75790;
	border-radius: 50%;
	display: inline-block;
	height: 2em;
	vertical-align: middle;
	width: 2em;
}

.track .stop .text {
	bottom: -2em;
	color: #48418e;
	display: inline-block;
	font-size: 3.6em;
	margin-top: 1em;
	position: absolute;
	text-transform: uppercase;
	-ms-transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
}

.skrollable {
	position: absolute;
}

.skrollable.fixed {
	position: fixed;
}

.skrollable.relative {
	position: relative;
}

.skrollable.static {
	position: static;
}

.continuous-train {
	position: absolute;
	left: -100%;
}

.train {
	position: absolute;
	width: 40em;
	right: 150%;
}

.train.animate.one {
	right: 50%;
}

@media only screen and (max-width: 559px) {

	.train.animate.one {
		right: 40%;
	}
}

.train.animate.two {
	right: 16%;
}

[data-start],
.animation {
	position: absolute;
	transition: 1s;
}


/* Main Nav */

.main-nav {
	background-color: #fff;
	height: 8em;
	left: 0;
	position: fixed;
	right: 0;
	text-align: center;
	top: 0;
	max-width: 1920px;
	z-index: 100;
}

.main-nav .list {
	display: inline-flex;
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;
	justify-content: space-around;
	width: 100%;
}

@media only screen and (max-width: 719px) {

	.main-nav .list {
		display: block;
	}
}

.main-nav .list .item {
	display: inline-block;
	height: 100%;
}

@media only screen and (max-width: 719px) {

	.main-nav .list .item {
		display: none;
	}
}

.main-nav .list .item .link {
	color: #48418e;
	display: block;
	font-size: 1.4286em;
	line-height: 5.6em;
	padding: 0 1.4em;
	text-decoration: none;
}

@media only screen and (max-width: 719px) {

	.main-nav .list .item.home {
		display: block;
	}
}

.main-nav .list .item.home .link {
	padding-bottom: 1.706em;
	padding-top: 1.305em;
}

.main-nav .list .item.home .image {
	display: inline-block;
	max-width: none;
	width: 11.2em;
}

.main-nav .menu-icon {
	display: none;
	cursor: pointer;
	float: right;
	position: absolute;
	top: 8px;
	right: 10px;
}

@media only screen and (max-width: 719px) {

	.main-nav .menu-icon {
		display: block;
	}
}

.main-nav .menu-icon .bar1,
.main-nav .menu-icon .bar2,
.main-nav .menu-icon .bar3 {
	width: 35px;
	height: 5px;
	background-color: #48418e;
	margin: 6px 0;
	transition: 0.4s;
}

.main-nav.open .list {
	border-bottom: 1px solid #cccccc;
}

.main-nav.open .item {
	display: block;
	background-color: #ffffff;
}

.main-nav.open .bar1 {
	-webkit-transform: rotate(-45deg) translate(-9px, 6px);
	transform: rotate(-45deg) translate(-9px, 6px);
}

.main-nav.open .bar2 {
	opacity: 0;
}

.main-nav.open .bar3 {
	-webkit-transform: rotate(45deg) translate(-8px, -8px);
	transform: rotate(45deg) translate(-8px, -8px);
}

.footer {
	background-color: #171249;
	color: #fff;
	padding: 8.1em 0 7.37em;
	position: relative;
}

.footer .wrapper {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding: 0 19.53125%;
	width: 100%;
}

.footer .content {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	display: inline-block;
	vertical-align: middle;
}

.footer .list {
	list-style-type: none;
}

.footer .link {
	color: #fff;
	text-decoration: none;
}

@media only screen and (max-width: 1599px) {

	.footer .wrapper {
		padding: 0 15%;
	}
}

.footer .wrapper.one {
	margin-bottom: 7.9em;
}

@media only screen and (max-width: 1599px) {

	.footer .wrapper.one {
		width: 100%;
	}
}

.footer .wrapper.one .content.one {
	width: 62.393162%;
}

@media only screen and (max-width: 559px) {

	.footer .wrapper.one .content.one {
		width: 100%;
		display: block;
		margin-bottom: 5em;
	}
}

.footer .wrapper.one .content.two {
	text-align: right;
	width: 37.606838%;
}

@media only screen and (max-width: 559px) {

	.footer .wrapper.one .content.two {
		width: 100%;
		display: block;
	}
}

.footer .wrapper.one .image {
	max-width: 100%;
}

.footer .wrapper.one .list {
	display: inline-block;
	margin-right: 5.454545%;
	text-align: left;
}

@media only screen and (max-width: 559px) {

	.footer .wrapper.one .list {
		padding: 0;
		margin-right: 0;
		display: block;
		text-align: center;
	}
}

.footer .wrapper.one .title {
	font-size: 3em;
	line-height: 1.5em;
}

.footer .wrapper.two .content.one {
	width: 16em;
}

@media only screen and (max-width: 559px) {

	.footer .wrapper.two .content.one {
		margin: auto;
		display: block;
	}
}

.footer .wrapper.two .content.two {
	text-align: right;
	width: calc(100% - 16em);
}

@media only screen and (max-width: 559px) {

	.footer .wrapper.two .content.two {
		width: 100%;
		text-align: center;
	}
}

.footer .wrapper.two .list {
	display: inline-block;
	text-align: center;
}

@media only screen and (max-width: 559px) {

	.footer .wrapper.two .list {
		padding: 0;
	}
}

.footer .wrapper.two .item {
	display: inline-block;
	margin: 0 3em;
	vertical-align: top;
}

@media only screen and (max-width: 559px) {

	.footer .wrapper.two .item {
		margin: 0 3em 0 0;
	}
}

.footer .wrapper.two .item:first-of-type {
	margin-left: 0;
}

@media only screen and (max-width: 559px) {

	.footer .wrapper.two .item:first-of-type {
		margin: 0;
		display: block;
	}
}

.footer .wrapper.two .item:last-of-type {
	margin-right: 0;
}

@media only screen and (max-width: 559px) {

	.footer .wrapper.two .item:last-of-type {
		margin: 0 0 0 3em;
	}
}

.footer .wrapper.two .text {
	font-size: 1.4em;
	line-height: 2.142857em;
}


/* Intro */

.intro .wrapper.one {
	margin-bottom: 11%;
	padding-bottom: 58.4375%;
}

@media only screen and (max-width: 1919px) {

	.intro .wrapper.one {
		margin-bottom: 16%;
	}
}

@media only screen and (max-width: 1599px) {

	.intro .wrapper.one {
		margin-bottom: 8%;
	}
}

@media only screen and (max-width: 1279px) {

	.intro .wrapper.one {
		margin-bottom: 5%;
	}
}

.intro .wrapper.one .content {
	bottom: 0;
	color: #fff;
	left: 19.5%;
	opacity: 0;
	top: 9em;
	width: 59.5em;
	z-index: 10;
	max-width: 75%;
}

@media only screen and (max-width: 1599px) {

	.intro .wrapper.one .content {
		top: 10em;
	}
}

@media only screen and (max-width: 1279px) {

	.intro .wrapper.one .content {
		top: 5em;
	}
}

.intro .wrapper.one .title.one {
	font-size: 9.2em;
	line-height: 1.125em;
	margin-bottom: 0.2em;
	font-weight: bold;
}

@media only screen and (max-width: 1023px) {

	.intro .wrapper.one .title.one {
		font-size: 7.8em;
	}
}

@media only screen and (max-width: 719px) {

	.intro .wrapper.one .title.one {
		font-size: 7.1em;
	}
}

@media only screen and (max-width: 559px) {

	.intro .wrapper.one .title.one {
		font-size: 5em;
	}
}

.intro .wrapper.one .title.two {
	bottom: 0;
	font-size: 1.8em;
	left: 0;
	line-height: 1.666667em;
	position: absolute;
}

.intro .wrapper.one .text {
	font-size: 3em;
	line-height: 1.7em;
}

@media only screen and (max-width: 559px) {

	.intro .wrapper.one .text {
		font-size: 2em;
	}
}

.intro .wrapper.one .brick.turquoise {
	opacity: 0;
}

.intro .wrapper.one .brick.one {
	height: 72.3em;
	width: 100%;
	z-index: 5;
}

@media only screen and (max-width: 559px) {

	.intro .wrapper.one .brick.one {
		height: 52.3em;
	}
}

.intro .wrapper.one .brick.two {
	left: 20%;
	top: 72.3em;
}

.intro .wrapper.one .brick.three {
	right: 0;
	top: 72.3em;
}

.intro .wrapper.one .brick.four {
	right: -20em;
	top: 32.3em;
}

.intro .wrapper.one .brick.five {
	left: -20em;
	top: 52.3em;
}

.intro .wrapper.one .brick.six {
	right: -20em;
	top: 52.3em;
}

.intro .wrapper.one .brick.seven,
.intro .wrapper.one .brick.eight {
	right: -20em;
	top: 72.3em;
}

.intro .wrapper.one .brick.nine,
.intro .wrapper.one .brick.ten,
.intro .wrapper.one .brick.eleven,
.intro .wrapper.one .brick.twelve {
	right: -20em;
	top: 92.3em;
}

.intro .wrapper.two .content {
	margin: 0 auto;
	padding: 13em 0 16.5em;
	text-align: center;
	width: 56em;
	max-width: 100%;
}

.intro .wrapper.two .title span {
	display: inline-block;
	width: 100%;
}

.intro .wrapper.two .title span.one {
	color: #48418e;
	font-size: 4em;
	line-height: 1.375em;
	margin-bottom: 7.25em;
}

@media only screen and (max-width: 1023px) {

	.intro .wrapper.two .title span.one {
		margin-bottom: 4.25em;
	}
}

.intro .wrapper.two .title span.two {
	color: #00b6bf;
	font-size: 3em;
	line-height: 1.5em;
}

.intro .wrapper.two .image {
	position: absolute;
	top: 45%;
}

.intro .wrapper.three {
	background-color: #d75790;
}

.intro .wrapper.three .content {
	color: #fff;
	padding: 8em 0 8.7em;
	text-align: center;
}

.intro .wrapper.three .content h2 {
	font-size: 4em;
	line-height: 1.375em;
	margin-bottom: 2.2em;
}

.intro .wrapper.three .col {
	display: inline-block;
	vertical-align: top;
	width: 45em;
}

@media only screen and (min-width: 920px) {

	.intro .wrapper.three .col {
		max-width: 33%;
	}
}

.intro .wrapper.three .col .title {
	font-size: 8em;
	line-height: 1.2em;
	margin-bottom: 0.375em;
}

.intro .wrapper.three .col .text {
	font-size: 2.4em;
	line-height: 1.5em;
	margin: 0 auto;
	max-width: 80%;
}

.intro .wrapper.three .col>.image {
	height: 27.7em;
}

@media only screen and (max-width: 1023px) {

	.intro .wrapper.three .col>.image {
		height: 23.7em;
	}
}

.intro .wrapper.three .col .image img {
	display: inline-block;
	margin-left: auto;
	margin-right: auto;
}

.intro .wrapper.three .col.one .image img {
	margin-top: 2.9em;
	width: 62.222222%;
}

.intro .wrapper.three .col.two .image img {
	margin-top: 3.3em;
	width: 67.777778%;
}

.intro .wrapper.three .col.three .image img {
	margin-top: 2.4em;
	width: 46.666667%;
}

.intro .wrapper.four {
	padding-bottom: 46.875%;
}

@media only screen and (max-width: 1023px) {

	.intro .wrapper.four {
		padding-bottom: 46.875%;
	}
}

@media only screen and (max-width: 719px) {

	.intro .wrapper.four {
		height: auto;
		padding-bottom: 1em;
	}
}

.intro .wrapper.four .video {
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
	line-height: 0;
}

@media only screen and (min-width: 720px) {

	.intro .wrapper.four .video {
		position: absolute;
	}
}

.intro .wrapper.four .video video {
	width: 100%;
}

.intro .wrapper.four .content {
	background-color: #d75790;
	-moz-box-shadow: 1em 1em #7c2d79;
	-webkit-box-shadow: 1em 1em #7c2d79;
	box-shadow: 1em 1em #7c2d79;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	color: #fff;
	padding: 4em 4.9em 5.5em 4.1em;
	right: 13.802083%;
	top: 29.375%;
	width: 60em;
}

@media only screen and (max-width: 1599px) {

	.intro .wrapper.four .content {
		right: 8%;
	}
}

@media only screen and (min-width: 720px) and (max-width: 919px) {

	.intro .wrapper.four .content {
		width: 40em;
		top: 20%;
	}
}

@media only screen and (max-width: 719px) {

	.intro .wrapper.four .content {
		display: block;
		position: initial;
		width: 99%;
	}
}

.intro .wrapper.four .title {
	font-size: 3em;
	line-height: 1.5em;
}

@media only screen and (max-width: 1023px) {

	.intro .wrapper.four .title {
		font-size: 2.5em;
	}
}

@media only screen and (max-width: 919px) {

	.intro .wrapper.four .title {
		font-size: 2em;
	}
}


/* Benefits */

.benefits .wrapper.one {
	padding-bottom: 900px;
}

@media only screen and (max-width: 919px) {

	.benefits .wrapper.one {
		padding-bottom: 0;
		height: auto;
	}

	.benefits .wrapper.one h2 {
		text-align: center;
	}
}

.benefits .wrapper.one .map {
	background: url("../img/uk-north-map.png") no-repeat #e5e4df;
	max-width: 1570px;
	height: 900px;
}

@media only screen and (max-width: 1279px) {

	.benefits .wrapper.one .map {
		position: absolute;
		top: 0;
		left: -28em;
		right: 0;
		bottom: 0;
	}
}

@media only screen and (max-width: 919px) {

	.benefits .wrapper.one .map {
		left: -17em;
	}
}

@media only screen and (max-width: 719px) {

	.benefits .wrapper.one .map {
		left: -37em;
	}
}

.benefits .wrapper.one .map .image {
	left: 301px;
	position: absolute;
	top: 260px;
}

@media only screen and (max-width: 919px) {

	.benefits .wrapper.one .map {
		display: none;
	}
}

.benefits .wrapper.one .map-mobile {
	display: none;
	margin-bottom: 2em;
}

@media only screen and (max-width: 919px) {

	.benefits .wrapper.one .map-mobile {
		display: block;
	}
}

.benefits .wrapper.one .city {
	position: absolute;
}

.benefits .wrapper.one .city .pulse {
	left: 50%;
	opacity: 0.1;
	position: absolute;
	top: 50%;
	-ms-transform: translateX(-50%) translateY(-50%);
	-webkit-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
}

.benefits .wrapper.one .city .pulse:after {
	animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
	background-color: #fff;
	border-radius: 50%;
	-moz-box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
	-webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
	content: "";
	display: block;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

.benefits .wrapper.one .city .pulse.one {
	height: 90px;
	width: 90px;
}

.benefits .wrapper.one .city .pulse.two {
	height: 70px;
	width: 70px;
}

.benefits .wrapper.one .city .pulse.three {
	height: 50px;
	width: 50px;
}

.benefits .wrapper.one .city .marker {
	border-radius: 50%;
	border: 4px solid #fff;
	height: 22px;
	left: 50%;
	position: absolute;
	top: 50%;
	-ms-transform: translateX(-50%) translateY(-50%);
	-webkit-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
	width: 22px;
}

.benefits .wrapper.one .city .title {
	color: #fff;
	font-size: 16px;
	position: absolute;
	text-align: center;
	text-transform: uppercase;
}

.benefits .wrapper.one .city.newcastle {
	left: 550px;
	top: 265px;
}

.benefits .wrapper.one .city.newcastle .marker {
	background-color: #64b554;
}

.benefits .wrapper.one .city.newcastle .title {
	left: -117px;
	top: -12px;
}

.benefits .wrapper.one .city.liverpool {
	left: 307px;
	top: 703px;
}

.benefits .wrapper.one .city.liverpool .marker {
	background-color: #7c2d79;
}

.benefits .wrapper.one .city.liverpool .title {
	left: -11px;
	top: -39px;
}

.benefits .wrapper.one .city.manairport {
	left: 402px;
	top: 728px;
}

.benefits .wrapper.one .city.manairport .marker {
	background-color: #c2cf32;
}

.benefits .wrapper.one .city.manairport .title {
	left: -79px;
	top: 25px;
}

.benefits .wrapper.one .city.manchester {
	left: 428px;
	top: 687px;
}

.benefits .wrapper.one .city.manchester .marker {
	background-color: #c2cf32;
}

.benefits .wrapper.one .city.manchester .title {
	left: -84px;
	top: -48px;
}

.benefits .wrapper.one .city.leeds {
	left: 557px;
	top: 649px;
}

.benefits .wrapper.one .city.leeds .marker {
	background-color: #d75790;
}

.benefits .wrapper.one .city.leeds .title {
	left: -69px;
	top: -30px;
}

.benefits .wrapper.one .city.sheffield {
	left: 577px;
	top: 747px;
}

.benefits .wrapper.one .city.sheffield .marker {
	background-color: #00b6bf;
}

.benefits .wrapper.one .city.sheffield .title {
	left: 23px;
	top: 14px;
}

.benefits .wrapper.one .city.hull {
	left: 738px;
	top: 634px;
}

.benefits .wrapper.one .city.hull .marker {
	background-color: #171249;
}

.benefits .wrapper.one .city.hull .title {
	left: -56px;
	top: -35px;
}

.benefits .wrapper.one .content {
	bottom: 0;
	right: 25.5em;
	top: 70px;
}

@media only screen and (max-width: 1919px) {

	.benefits .wrapper.one .content {
		right: 15em;
	}
}

@media only screen and (max-width: 1599px) {

	.benefits .wrapper.one .content {
		right: 5em;
		top: 4em;
	}
}

@media only screen and (max-width: 919px) {

	.benefits .wrapper.one .content {
		position: relative;
		display: block;
		right: 0;
		top: 0;
	}
}

.benefits .wrapper.one .content .title {
	color: #00b6bf;
	font-size: 4em;
	line-height: 1.125em;
	margin-bottom: 1.625em;
}

@media only screen and (max-width: 1599px) {

	.benefits .wrapper.one .content .title {
		margin-bottom: 0.8em;
	}
}

.benefits .wrapper.one .blocks {
	position: relative;
	width: 69em;
}

@media only screen and (max-width: 919px) {

	.benefits .wrapper.one .blocks {
		min-height: 420px;
		position: relative;
		width: 100%;
	}
}

@media only screen and (max-width: 559px) {

	.benefits .wrapper.one .blocks {
		min-height: 840px;
	}
}

.benefits .wrapper.one .block {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	color: #fff;
	display: inline-block;
	padding: 3em;
	vertical-align: top;
	width: 50%;
	left: 200%;
}

@media only screen and (max-width: 1599px) {

	.benefits .wrapper.one .block {
		padding: 2em;
	}
}

@media only screen and (max-width: 719px) {

	.benefits .wrapper.one .block {
		min-height: 155px;
	}
}

@media only screen and (max-width: 559px) {

	.benefits .wrapper.one .block {
		min-height: 140px;
		height: 140px !important;
		width: 100%;
	}
}

.benefits .wrapper.one .block.one,
.benefits .wrapper.one .block.two {
	height: 24em;
	top: 0;
}

@media only screen and (max-width: 1599px) {

	.benefits .wrapper.one .block.one,
	.benefits .wrapper.one .block.two {
		height: 20em;
	}
}

.benefits .wrapper.one .block.one {
	background-color: #c2cf32;
}

.benefits .wrapper.one .block.one.animate {
	left: 0;
}

.benefits .wrapper.one .block.two {
	background-color: #48418e;
}

.benefits .wrapper.one .block.two.animate {
	left: 50%;
}

@media only screen and (max-width: 559px) {

	.benefits .wrapper.one .block.two {
		top: 140px;
	}
}

.benefits .wrapper.one .block.three,
.benefits .wrapper.one .block.four {
	height: 27em;
	top: 24em;
}

@media only screen and (max-width: 1599px) {

	.benefits .wrapper.one .block.three,
	.benefits .wrapper.one .block.four {
		height: 22em;
		top: 20em;
	}
}

.benefits .wrapper.one .block.three {
	background-color: #00b6bf;
}

.benefits .wrapper.one .block.three.animate {
	left: 0;
}

@media only screen and (max-width: 559px) {

	.benefits .wrapper.one .block.three {
		top: 280px;
	}
}

.benefits .wrapper.one .block.four {
	background-color: #e8e14b;
	color: #48418e;
}

.benefits .wrapper.one .block.four.animate {
	left: 50%;
}

@media only screen and (max-width: 559px) {

	.benefits .wrapper.one .block.four {
		top: 420px;
	}
}

.benefits .wrapper.one .block.five,
.benefits .wrapper.one .block.six {
	height: 21em;
	top: 51em;
}

@media only screen and (max-width: 1599px) {

	.benefits .wrapper.one .block.five,
	.benefits .wrapper.one .block.six {
		height: 18em;
		top: 42em;
	}
}

.benefits .wrapper.one .block.five {
	background-color: #568280;
}

.benefits .wrapper.one .block.five.animate {
	left: 0;
}

@media only screen and (max-width: 559px) {

	.benefits .wrapper.one .block.five {
		top: 560px;
	}
}

.benefits .wrapper.one .block.six {
	background-color: #d75790;
}

.benefits .wrapper.one .block.six.animate {
	left: 50%;
}

@media only screen and (max-width: 559px) {

	.benefits .wrapper.one .block.six {
		top: 700px;
	}
}

@media only screen and (max-width: 559px) {

	.benefits .wrapper.one .block.animate {
		left: 0 !important;
	}
}

.benefits .wrapper.one .block .images {
	height: 7em;
	margin-bottom: 2em;
}

.benefits .wrapper.one .block .image {
	display: inline-block;
	margin-right: 2%;
	max-height: 100%;
	vertical-align: middle;
}

.benefits .wrapper.one .block .text {
	font-size: 1.8em;
	line-height: 1.666667em;
}

@media only screen and (max-width: 1599px) {

	.benefits .wrapper.one .block .text {
		font-size: 1.6em;
		line-height: 1.2em;
	}
}

@media only screen and (max-width: 719px) {

	.benefits .wrapper.one .block .text {
		font-size: 1.8em;
		line-height: 1.35em;
	}
}

@media only screen and (max-width: 559px) {

	.benefits .wrapper.one .block .text {
		font-size: 2em;
		line-height: 1.35em;
	}
}

.benefits .wrapper.two {
	background-color: #171249;
	padding: 7em;
}

.benefits .wrapper.two .content {
	color: #fff;
	margin: 0 auto;
	text-align: center;
	width: 93em;
}

.benefits .wrapper.two .text {
	font-size: 1.8em;
	line-height: 1.666667em;
}


/* Transforming */

.transforming .wrapper.one {
	padding-bottom: 46.875%;
}

@media only screen and (max-width: 559px) {

	.transforming .wrapper.one {
		height: auto;
		padding-top: 46.75%;
		padding-bottom: 0;
	}

	.transforming .wrapper.one .brick {
		display: none;
	}
}

.transforming .wrapper.one .content {
	background-color: #00b6bf;
	background-color: rgba(0, 182, 191, 0.9);
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	color: #fff;
	padding: 3.2em 4em 5.3em;
	top: 25%;
	width: 69em;
	z-index: 5;
	left: -100%;
}

@media only screen and (max-width: 1279px) {

	.transforming .wrapper.one .content {
		top: 17%;
	}
}

@media only screen and (max-width: 919px) {

	.transforming .wrapper.one .content {
		top: 10%;
	}
}

@media only screen and (max-width: 719px) {

	.transforming .wrapper.one .content {
		display: block;
		width: 85%;
	}
}

@media only screen and (max-width: 559px) {

	.transforming .wrapper.one .content {
		position: initial;
		width: 100%;
	}
}

.transforming .wrapper.one .content.animate {
	left: 19.53125%;
}

@media only screen and (max-width: 719px) {

	.transforming .wrapper.one .content.animate {
		left: 9.53125%;
	}
}

.transforming .wrapper.one .title.one {
	font-size: 4em;
	line-height: 1.375em;
	margin-bottom: 0.5em;
}

@media only screen and (max-width: 1023px) {

	.transforming .wrapper.one .title.one {
		font-size: 3.5em;
	}
}

.transforming .wrapper.one .title.two {
	font-size: 3em;
	line-height: 1.5em;
}

@media only screen and (max-width: 1023px) {

	.transforming .wrapper.one .title.two {
		font-size: 2.5em;
	}
}

.transforming .wrapper.one .title.three {
	bottom: 1.611111em;
	color: #fff;
	font-size: 1.8em;
	left: 19.5313%;
	line-height: 1.666667em;
	position: absolute;
}

@media only screen and (max-width: 559px) {

	.transforming .wrapper.one .title.three {
		top: 2%;
	}
}

.transforming .wrapper.one .brick.mustard {
	right: -20em;
	top: 0;
}

.transforming .wrapper.one .brick.mustard.animate {
	right: 0;
}

.transforming .wrapper.one .brick.white {
	right: -20em;
	opacity: 0;
	top: 20em;
}

.transforming .wrapper.one .brick.white.animate {
	opacity: 1;
	right: 20em;
}

.transforming .wrapper.one .brick.purple {
	right: -60em;
	top: 20em;
}

.transforming .wrapper.one .brick.purple.animate {
	right: 0;
}

.transforming .wrapper.one .brick.turquoise {
	opacity: 0;
	right: -20em;
	top: 40em;
}

.transforming .wrapper.one .brick.turquoise.animate {
	opacity: 1;
	right: 40em;
}

.transforming .wrapper.one .brick.yellow {
	right: -80em;
	top: 40em;
}

.transforming .wrapper.one .brick.yellow.animate {
	right: 0;
}

@media only screen and (max-width: 719px) {

	.transforming .wrapper.two {
		padding: 12em 0 52.3em;
	}
}

.transforming .wrapper.two .content {
	right: 150%;
}

.transforming .wrapper.two .content.one {
	-moz-box-shadow: 1em 1em #d75790;
	-webkit-box-shadow: 1em 1em #d75790;
	box-shadow: 1em 1em #d75790;
	width: 59.2em;
}

.transforming .wrapper.two .content.one .title {
	bottom: 1em;
	color: #fff;
	font-size: 1.8em;
	left: 1.111111em;
	line-height: 1.666667em;
	position: absolute;
}

.transforming .wrapper.two .content.one .image {
	width: 100%;
}

.transforming .wrapper.two .content.one.animate {
	right: 49.635417%;
}

@media only screen and (max-width: 919px) {

	.transforming .wrapper.two .content.one {
		width: 49.2em;
	}
}

.transforming .wrapper.two .content.two {
	width: 48em;
}

.transforming .wrapper.two .content.two .title.one {
	color: #48418e;
	font-size: 4em;
	line-height: 1.375em;
	margin-bottom: 0.5em;
}

.transforming .wrapper.two .content.two .title.two {
	color: #00b6bf;
	font-size: 3em;
	line-height: 1.5em;
}

.transforming .wrapper.two .content.two.animate {
	left: 57.03125%;
}

@media only screen and (max-width: 719px) {

	.transforming .wrapper.two .content {
		display: block;
		position: initial;
		width: 90% !important;
		margin: 5%;
	}
}

@media only screen and (max-width: 719px) {

	.transforming .wrapper.two {
		padding-bottom: 12em;
	}
}

.transforming .wrapper.three {
	text-align: center;
}

.transforming .wrapper.three .content {
	display: inline-block;
}

.transforming .wrapper.three .content .text {
	color: #48418e;
	font-size: 2.4em;
	line-height: 1.458333em;
}

.transforming .wrapper.three .content .text .title {
	display: inline-block;
	font-size: 3.333333em;
	line-height: 1.175em;
	margin-bottom: 0.375em;
}

.transforming .wrapper.three .content.one {
	margin-right: 7.8125%;
}

.transforming .wrapper.three .content.one .text .title {
	color: #c2cf32;
}

.transforming .wrapper.three .content.two {
	margin-left: 7.8125%;
}

.transforming .wrapper.three .content.two .text .title {
	color: #64b554;
}

@media only screen and (max-width: 719px) {

	.transforming .wrapper.four {
		padding-bottom: 22.4em;
	}
}

.transforming .wrapper.five {
	margin-bottom: 1em;
	padding-bottom: 63.9em;
	text-align: center;
	background-size: contain;
	background-position-x: center;
	background-position-y: bottom;
	background-repeat: no-repeat;
}

@media only screen and (max-width: 1023px) {

	.transforming .wrapper.five {
		padding-bottom: 43.9em;
	}
}

@media only screen and (max-width: 919px) {

	.transforming .wrapper.five {
		padding-bottom: 38.9em;
	}
}

@media only screen and (max-width: 719px) {

	.transforming .wrapper.five {
		padding-bottom: 28.9em;
	}
}

@media only screen and (max-width: 559px) {

	.transforming .wrapper.five {
		padding-bottom: 18.9em;
	}
}

.transforming .wrapper.five .content {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	color: #fff;
	display: inline-block;
	position: relative;
	width: 117em;
}

.transforming .wrapper.five .box {
	background-color: #7c2d79;
	-moz-box-shadow: 1em 1em #e5e4df;
	-webkit-box-shadow: 1em 1em #e5e4df;
	box-shadow: 1em 1em #e5e4df;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding: 6.8em 5em 5.7em 7em;
	position: relative;
	width: 100%;
}

.transforming .wrapper.five .static {
	opacity: 0;
	position: initial;
}

.transforming .wrapper.five .static.animate {
	opacity: 1;
}

.transforming .wrapper.five .quote {
	margin: 0 0 4em;
	text-align: left;
}

.transforming .wrapper.five .quote .text {
	font-size: 3em;
	line-height: 1.333333em;
}

.transforming .wrapper.five .cite {
	display: block;
	font-size: 2.4em;
	font-style: normal;
	line-height: 1.458333em;
	text-align: right;
}

.transforming .wrapper.five .name {
	color: #e8e14b;
}

.transforming .wrapper.five .image {
	position: absolute;
	z-index: 5;
}

.transforming .wrapper.five .image.one {
	left: -8em;
	top: -6em;
}

.transforming .wrapper.five .image.two {
	bottom: -6em;
	right: -8em;
}

.transforming .wrapper.six {
	background-color: #48418e;
	text-align: center;
}

.transforming .wrapper.six .content {
	color: #fff;
	display: inline-block;
	padding: 10.5em 0;
	width: 100%;
}

.transforming .wrapper.six .title {
	font-size: 4em;
	line-height: 1.375em;
	margin-bottom: 2em;
}

.transforming .wrapper.six .col {
	display: inline-block;
	margin: 3em 2.1%;
	vertical-align: top;
}

@media only screen and (min-width: 560px) and (max-width: 719px) {

	.transforming .wrapper.six .col {
		width: 45%;
	}
}

@media only screen and (max-width: 559px) {

	.transforming .wrapper.six .col {
		display: block;
	}
}

.transforming .wrapper.six .image {
	height: 14em;
	margin: 0 auto 3em;
}

@media only screen and (max-width: 719px) {

	.transforming .wrapper.six .image {
		margin-bottom: 1em;
	}
}

.transforming .wrapper.six .text {
	font-size: 2.4em;
	line-height: 1.25em;
}

.future .wrapper {
	background-color: #00b6bf;
	height: 800px;
	overflow: hidden;
}

@media only screen and (max-width: 559px) {

	.future .wrapper {
		height: auto;
	}
}

.future .wrapper>.image {
	max-width: none;
	position: absolute;
	width: 4250px;
}

@media only screen and (max-width: 559px) {

	.future .wrapper>.image {
		position: initial;
		max-width: none;
		margin-left: -17em;
		margin-bottom: -10%;
		width: 160% !important;
		margin-top: 37em !important;
	}
}

.future .wrapper>.image.zoomout {
	right: 100px;
	top: -260px;
	-moz-transition: all 2s ease-in-out;
	-webkit-transition: all 2s ease-in-out;
	transition: all 2s ease-in-out;
	width: 1047px;
}

@media only screen and (max-width: 1919px) {

	.future .wrapper>.image.zoomout {
		right: 40px;
	}
}

@media only screen and (max-width: 1599px) {

	.future .wrapper>.image.zoomout {
		right: 60px;
		top: -240px;
	}
}

@media only screen and (max-width: 1279px) {

	.future .wrapper>.image.zoomout {
		right: -230px;
	}
}

.future .content {
	top: 15.3em;
	width: 54em;
	left: -100%;
	opacity: 0;
}

.future .content.animate {
	left: 14.84375%;
	opacity: 1;
}

@media only screen and (max-width: 919px) {

	.future .content.animate {
		left: 7%;
	}
}

@media only screen and (max-width: 719px) {

	.future .content.animate {
		left: 0;
	}
}

@media only screen and (max-width: 559px) {

	.future .content {
		width: 100%;
		z-index: 10;
		top: 0;
		height: 400px;
	}
}

.future .content .image {
	left: -3em;
	position: absolute;
	top: -4em;
}

@media only screen and (max-width: 559px) {

	.future .content .image {
		left: -1em;
		top: -1em;
	}
}

.future .main {
	color: #fff;
}

@media only screen and (max-width: 1599px) {

	.future .main {
		background-color: rgba(72, 65, 142, 0.75);
		padding: 3em;
	}
}

.future .main .text {
	font-size: 1.8em;
	line-height: 1.666667em;
	margin-bottom: 3.333333em;
}

@media only screen and (max-width: 559px) {

	.future .main .text {
		margin: 0;
	}
}

@media only screen and (max-width: 559px) {

	.future .main {
		min-height: 40%;
	}
}

.future .title {
	font-size: 3em;
	line-height: 1.5em;
	margin-bottom: 0.666667em;
}

.future .legend {
	background-color: #fff;
	border: 0.4em solid #00b6bf;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	display: block;
	color: #48418e;
	padding: 1.4em 2em;
}

@media only screen and (max-width: 559px) {

	.future .legend {
		min-height: 50%;
	}
}

.future .legend .row {
	display: inline-block;
	margin: 1em 0;
	width: 100%;
}

.future .legend .item {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	display: inline-block;
	vertical-align: middle;
	width: 50%;
}

.future .legend .item .text {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	display: inline-block;
	font-size: 1.4em;
	padding: 0 0.357143em;
	vertical-align: middle;
	width: calc(100% - 3em);
}

.future .legend .item .text.blue {
	background-color: #48418e;
	color: #fff;
	padding: 0.642857em 0.785714em;
}

.future .legend .symbol {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	display: inline-block;
	padding-right: 0.5em;
	vertical-align: middle;
	width: 2.5em;
}

.future .legend .line {
	display: inline-block;
	height: 0.6em;
	width: 2em;
}

.future .legend .line.purple {
	background-color: #7c2d79;
}

.future .legend .line.yellow {
	background-color: #c2cf32;
}

.future .legend .line.green {
	background-color: #64b554;
}

.future .legend .line.teal {
	background-color: #568280;
}

.future .legend .line.black {
	background-color: #171249;
}

.future .legend .line.grey {
	background-color: #9b9b9b;
}

.future .legend .circle {
	border-radius: 50%;
	border-style: solid;
	border-width: 0.3em;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	display: inline-block;
	height: 2em;
	width: 2em;
}

.future .legend .circle.blue {
	background-color: #fff;
	border-color: #48418e;
}

.future .legend .circle.turquoise {
	background-color: #00b6bf;
	border-color: #00b6bf;
}

.future .junctions {
	background-color: #fff;
	border: 0.4em solid #e5e4df;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding: 1.4em 2.4em 2.2em 1.6em;
	width: 28em;
	right: -100%;
	opacity: 0;
	top: 6em;
}

@media only screen and (max-width: 559px) {

	.future .junctions {
		top: auto;
		bottom: 0;
		width: 100%;
	}
}

.future .junctions.animate {
	right: 3.125%;
	opacity: 1;
}

@media only screen and (max-width: 719px) {

	.future .junctions.animate {
		right: 0;
	}
}

.future .junctions .title {
	color: #48418e;
	font-size: 1.4em;
	line-height: 1.4em;
}

.future .junctions .row {
	margin-top: 2em;
}

@media only screen and (max-width: 559px) {

	.future .junctions .row {
		width: 49%;
		display: inline-block;
	}
}

.future .junctions .image {
	display: inline-block;
	vertical-align: middle;
	width: 10.416667%;
}

.future .junctions .text {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	color: #48418e;
	display: inline-block;
	font-size: 1.3em;
	line-height: 1.454545em;
	padding: 0 1em;
	vertical-align: middle;
	width: 89.583333%;
}

.expectations .wrapper.one {
	margin: 0 auto;
	width: 117em;
}

@media only screen and (max-width: 919px) {

	.expectations .wrapper.one {
		padding: 0 2.5%;
		width: 95%;
	}
}

@media only screen and (max-width: 559px) {

	.expectations .wrapper.one {
		padding: 0 5%;
		width: 90%;
	}
}

.expectations .wrapper.one .content {
	padding-top: 12em;
}

.expectations .wrapper.one .content.one .title.one {
	color: #48418e;
	font-size: 4em;
	line-height: 1.375em;
	margin-bottom: 0.75em;
}

.expectations .wrapper.one .content.one .title.two {
	color: #00b6bf;
	font-size: 3em;
	line-height: 1.5em;
	margin-bottom: 0.75em;
}

.expectations .wrapper.one .content.two .col.image .image,
.expectations .wrapper.one .content.four .col.image .image {
	width: 72.222222%;
}

.expectations .wrapper.one .content.three .col.image {
	text-align: right;
}

.expectations .wrapper.one .content.three .col.image .image {
	display: inline-block;
	width: 86.111111%;
}

.expectations .wrapper.one .content.three .text .regular {
	display: inline-block;
	padding-right: 15%;
}

.expectations .wrapper.one .content.five {
	padding: 12.5em 0 12em;
}

.expectations .wrapper.one .content.five .text {
	color: #434343;
	font-size: 1.8em;
	line-height: 1.666667em;
}

.expectations .wrapper.one .col {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	display: inline-block;
	vertical-align: middle;
}

.expectations .wrapper.one .col.main {
	width: 70%;
}

.expectations .wrapper.one .col.image {
	width: 30%;
}

.expectations .wrapper.one .text .title {
	color: #00b6bf;
}

.expectations .wrapper.one .text .title.one {
	font-size: 6em;
	line-height: 1.166667em;
}

@media only screen and (max-width: 559px) {

	.expectations .wrapper.one .text .title.one {
		font-size: 4em;
	}
}

.expectations .wrapper.one .text .title.two {
	display: inline-block;
	font-size: 12em;
	line-height: 1.175em;
}

@media only screen and (max-width: 559px) {

	.expectations .wrapper.one .text .title.two {
		font-size: 7em;
	}
}

.expectations .wrapper.one .text .regular {
	color: #48418e;
	font-size: 2.4em;
	line-height: 1.458333em;
}

.nextsteps {
	position: relative;
}

.nextsteps .wrapper.one {
	background: url("../img/all-cityscapes.png") bottom center no-repeat #48418e;
	padding-bottom: 80em;
}

.nextsteps .wrapper.one .content {
	color: #fff;
	top: 29.375%;
	width: 80.9em;
	left: -100%;
	opacity: 0;
}

@media only screen and (max-width: 919px) {

	.nextsteps .wrapper.one .content {
		width: 60em;
	}
}

.nextsteps .wrapper.one .content.animate {
	left: 19.583333%;
	opacity: 1;
}

@media only screen and (max-width: 559px) {

	.nextsteps .wrapper.one .content {
		width: 75%;
		top: 20%;
	}

	.nextsteps .wrapper.one .content.animate {
		left: 13%;
	}
}

.nextsteps .wrapper.one .title {
	font-size: 4em;
	line-height: 1.375em;
}

.nextsteps .wrapper.one .image {
	left: -3em;
	position: absolute;
	top: -5em;
}

.nextsteps .wrapper.two {
	padding-bottom: 41.666667%;
	z-index: 2;
}

.nextsteps .wrapper.two .overlay {
	background-color: #00b6bf;
	bottom: 0;
	left: 0;
	opacity: 0.6;
	position: absolute;
	right: 0;
	top: 0;
}

.nextsteps .wrapper.two .content {
	bottom: 0;
	color: #fff;
	left: 17.760417%;
	top: 0;
	width: 39em;
}

@media only screen and (max-width: 919px) {

	.nextsteps .wrapper.two .content {
		width: 59em;
	}
}

@media only screen and (max-width: 719px) {

	.nextsteps .wrapper.two .content {
		width: 56em;
	}
}

@media only screen and (max-width: 559px) {

	.nextsteps .wrapper.two .content {
		width: 39em;
		left: 10%;
	}
}

.nextsteps .wrapper.two .title.one {
	font-size: 4em;
	line-height: 1.375em;
	margin: 6.7em 0 0.5em;
}

@media only screen and (max-width: 1919px) {

	.nextsteps .wrapper.two .title.one {
		margin-top: 6em;
	}
}

@media only screen and (max-width: 1599px) {

	.nextsteps .wrapper.two .title.one {
		margin-top: 5em;
	}
}

@media only screen and (max-width: 1279px) {

	.nextsteps .wrapper.two .title.one {
		margin-top: 4em;
	}
}

@media only screen and (max-width: 1023px) {

	.nextsteps .wrapper.two .title.one {
		margin-top: 3em;
	}
}

@media only screen and (max-width: 919px) {

	.nextsteps .wrapper.two .title.one {
		margin-top: 2.5em;
	}
}

@media only screen and (max-width: 559px) {

	.nextsteps .wrapper.two .title.one {
		font-size: 2.5em;
	}
}

.nextsteps .wrapper.two .title.two {
	font-size: 3em;
	line-height: 1.5em;
}

@media only screen and (max-width: 559px) {

	.nextsteps .wrapper.two .title.two {
		font-size: 2em;
	}
}

.nextsteps .wrapper.two .title.three {
	bottom: 7.25%;
	font-size: 1.8em;
	left: 0;
	line-height: 1.666667em;
	position: absolute;
}

.intro .wrapper.one {
	background-image: url("/tftn_north_statics/css/general/img/hero.jpg");
}

.intro .wrapper.one .content {
	width: 70em;
}

.transforming .wrapper.one {
	background-image: url("/tftn_north_statics/css/general/img/transforming.jpg");
}

.transforming .wrapper.two {
	padding: 12em 0 62.3em;
}

@media only screen and (max-width: 719px) {

	.transforming .wrapper.two {
		padding-bottom: 12em;
	}
}

.transforming .wrapper.two .content.one .image {
	max-height: none;
}

.transforming .wrapper.two .content.two {
	width: 50em;
}

@media only screen and (max-width: 919px) {

	.transforming .wrapper.two .content.two {
		width: 40em;
	}

	.transforming .wrapper.two .content.two .title.two {
		font-size: 2.5em;
	}
}

.transforming .wrapper.four {
	padding-bottom: 32.4em;
}

.transforming .wrapper.five {
	background-image: url("/tftn_north_statics/css/general/img/manchester-cityscape.png");
}

.future .wrapper>.image {
	right: -1620px;
	top: -3030px;
}

@media only screen and (max-width: 1919px) {

	.future .wrapper>.image {
		right: -1680px;
	}
}

@media only screen and (max-width: 1599px) {

	.future .wrapper>.image {
		right: -1930px;
	}
}

@media only screen and (max-width: 1279px) {

	.future .wrapper>.image {
		right: -1980px;
	}
}

.expectations .wrapper.one .content.two .title.two {
	@keyframes pulse-dot {

		0% {
			-ms-transform: scale(0.8);
			-webkit-transform: scale(0.8);
			transform: scale(0.8);
		}

		50% {
			-ms-transform: scale(1);
			-webkit-transform: scale(1);
			transform: scale(1);
		}

		100% {
			-ms-transform: scale(0.8);
			-webkit-transform: scale(0.8);
			transform: scale(0.8);
		}
	}
	@font-face {
		font-family: "Korto Book";
		src: url("/fonts/Talbot Type - Korto-Book.otf") format("opentype");
		font-weight: 300;
	}
	@font-face {
		font-family: "Korto Bold";
		src: url("/fonts/Talbot Type - Korto-Bold.otf") format("opentype");
		font-weight: 300;
	}

	body {
		font-family: "Korto Book", Arial, Helvetica, sans-serif;
		font-size: 10px;
	}

	html,
	body {
		overflow-x: hidden;
	}

	body {
		margin: 0;
	}
	@media only screen and (max-width: 1919px) {

		body {
			font-size: 9px;
		}
	}
	@media only screen and (max-width: 1599px) {

		body {
			font-size: 8px;
		}
	}
	@media only screen and (max-width: 1279px) {

		body {
			font-size: 7px;
		}
	}

	.container {
		margin: 8em auto 0;
		max-width: 1920px;
	}

	.wrapper {
		position: relative;
		max-width: 100%;
	}

	.wrapper.bg {
		background-repeat: no-repeat;
		background-size: contain;
		height: 0;
	}

	.wrapper.bg .content {
		position: absolute;
	}

	.title {
		font-family: "Korto Bold", Arial, Helvetica, sans-serif;
		font-weight: normal;
		margin: 0;
	}

	.text {
		margin: 0;
	}

	.image {
		display: block;
		max-width: 100%;
	}

	.content {
		max-width: 100%;
	}
	@media only screen and (max-width: 919px) {

		.brick-container {
			position: absolute;
			top: -100px;
			right: 0;
		}
	}
	@media only screen and (max-width: 719px) {

		.brick-container {
			top: -200px;
			opacity: 0.75;
		}
	}
	@media only screen and (max-width: 559px) {

		.brick-container {
			top: -300px;
			opacity: 0.5;
		}
	}

	.brick {
		display: inline-block;
		height: 20em;
		position: absolute;
		width: 20em;
	}
	@media only screen and (max-width: 1599px) {

		.brick {
			font-size: 0.8em;
		}
	}
	@media only screen and (max-width: 1279px) {

		.brick {
			font-size: 0.7em;
		}
	}

	.brick.doublew {
		width: 40em;
	}

	.brick.doubleh {
		height: 40em;
	}

	.brick.mustard {
		background-color: #c2cf32;
	}

	.brick.white {
		background-color: #fff;
	}

	.brick.grey {
		background-color: #e5e4df;
	}

	.brick.green {
		background-color: #64b554;
	}

	.brick.yellow {
		background-color: #e8e14b;
	}

	.brick.magenta {
		background-color: #d75790;
	}

	.brick.purple {
		background-color: #48418e;
	}

	.brick.maroon {
		background-color: #7c2d79;
	}

	.brick.turquoise {
		background-color: #00b6bf;
	}

	.track {
		padding-top: 5em;
	}

	.track .line {
		background-color: #48418e;
		display: inline-block;
		height: 0.6em;
		position: relative;
		vertical-align: middle;
		width: 100%;
	}

	.track .stop {
		background-color: #fff;
		display: inline-block;
		padding: 0 0.9em;
		position: absolute;
		top: -0.7em;
	}

	.track .stop .dot {
		background-color: #d75790;
		border-radius: 50%;
		display: inline-block;
		height: 2em;
		vertical-align: middle;
		width: 2em;
	}

	.track .stop .text {
		bottom: -2em;
		color: #48418e;
		display: inline-block;
		font-size: 3.6em;
		margin-top: 1em;
		position: absolute;
		text-transform: uppercase;
		-ms-transform: translateX(-50%);
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
	}

	.skrollable {
		position: absolute;
	}

	.skrollable.fixed {
		position: fixed;
	}

	.skrollable.relative {
		position: relative;
	}

	.skrollable.static {
		position: static;
	}

	.continuous-train {
		position: absolute;
		left: -100%;
	}

	.train {
		position: absolute;
		width: 40em;
		right: 150%;
	}

	.train.animate.one {
		right: 50%;
	}
	@media only screen and (max-width: 559px) {

		.train.animate.one {
			right: 40%;
		}
	}

	.train.animate.two {
		right: 16%;
	}

	[data-start],
	.animation {
		position: absolute;
		transition: 1s;
	}

	/* Main Nav */
	.main-nav {
		background-color: #fff;
		height: 8em;
		left: 0;
		position: fixed;
		right: 0;
		text-align: center;
		top: 0;
		max-width: 1920px;
		z-index: 100;
	}

	.main-nav .list {
		display: inline-flex;
		list-style: none;
		margin: 0;
		padding: 0;
		position: relative;
		justify-content: space-around;
		width: 100%;
	}
	@media only screen and (max-width: 719px) {

		.main-nav .list {
			display: block;
		}
	}

	.main-nav .list .item {
		display: inline-block;
		height: 100%;
	}
	@media only screen and (max-width: 719px) {

		.main-nav .list .item {
			display: none;
		}
	}

	.main-nav .list .item .link {
		color: #48418e;
		display: block;
		font-size: 1.4286em;
		line-height: 5.6em;
		padding: 0 1.4em;
		text-decoration: none;
	}
	@media only screen and (max-width: 719px) {

		.main-nav .list .item.home {
			display: block;
		}
	}

	.main-nav .list .item.home .link {
		padding-bottom: 1.706em;
		padding-top: 1.305em;
	}

	.main-nav .list .item.home .image {
		display: inline-block;
		max-width: none;
		width: 11.2em;
	}

	.main-nav .menu-icon {
		display: none;
		cursor: pointer;
		float: right;
		position: absolute;
		top: 8px;
		right: 10px;
	}
	@media only screen and (max-width: 719px) {

		.main-nav .menu-icon {
			display: block;
		}
	}

	.main-nav .menu-icon .bar1,
	.main-nav .menu-icon .bar2,
	.main-nav .menu-icon .bar3 {
		width: 35px;
		height: 5px;
		background-color: #48418e;
		margin: 6px 0;
		transition: 0.4s;
	}

	.main-nav.open .list {
		border-bottom: 1px solid #cccccc;
	}

	.main-nav.open .item {
		display: block;
		background-color: #ffffff;
	}

	.main-nav.open .bar1 {
		-webkit-transform: rotate(-45deg) translate(-9px, 6px);
		transform: rotate(-45deg) translate(-9px, 6px);
	}

	.main-nav.open .bar2 {
		opacity: 0;
	}

	.main-nav.open .bar3 {
		-webkit-transform: rotate(45deg) translate(-8px, -8px);
		transform: rotate(45deg) translate(-8px, -8px);
	}

	.footer {
		background-color: #171249;
		color: #fff;
		padding: 8.1em 0 7.37em;
		position: relative;
	}

	.footer .wrapper {
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		padding: 0 19.53125%;
		width: 100%;
	}

	.footer .content {
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		display: inline-block;
		vertical-align: middle;
	}

	.footer .list {
		list-style-type: none;
	}

	.footer .link {
		color: #fff;
		text-decoration: none;
	}
	@media only screen and (max-width: 1599px) {

		.footer .wrapper {
			padding: 0 15%;
		}
	}

	.footer .wrapper.one {
		margin-bottom: 7.9em;
	}
	@media only screen and (max-width: 1599px) {

		.footer .wrapper.one {
			width: 100%;
		}
	}

	.footer .wrapper.one .content.one {
		width: 62.393162%;
	}
	@media only screen and (max-width: 559px) {

		.footer .wrapper.one .content.one {
			width: 100%;
			display: block;
			margin-bottom: 5em;
		}
	}

	.footer .wrapper.one .content.two {
		text-align: right;
		width: 37.606838%;
	}
	@media only screen and (max-width: 559px) {

		.footer .wrapper.one .content.two {
			width: 100%;
			display: block;
		}
	}

	.footer .wrapper.one .image {
		max-width: 100%;
	}

	.footer .wrapper.one .list {
		display: inline-block;
		margin-right: 5.454545%;
		text-align: left;
	}
	@media only screen and (max-width: 559px) {

		.footer .wrapper.one .list {
			padding: 0;
			margin-right: 0;
			display: block;
			text-align: center;
		}
	}

	.footer .wrapper.one .title {
		font-size: 3em;
		line-height: 1.5em;
	}

	.footer .wrapper.two .content.one {
		width: 16em;
	}
	@media only screen and (max-width: 559px) {

		.footer .wrapper.two .content.one {
			margin: auto;
			display: block;
		}
	}

	.footer .wrapper.two .content.two {
		text-align: right;
		width: calc(100% - 16em);
	}
	@media only screen and (max-width: 559px) {

		.footer .wrapper.two .content.two {
			width: 100%;
			text-align: center;
		}
	}

	.footer .wrapper.two .list {
		display: inline-block;
		text-align: center;
	}
	@media only screen and (max-width: 559px) {

		.footer .wrapper.two .list {
			padding: 0;
		}
	}

	.footer .wrapper.two .item {
		display: inline-block;
		margin: 0 3em;
		vertical-align: top;
	}
	@media only screen and (max-width: 559px) {

		.footer .wrapper.two .item {
			margin: 0 3em 0 0;
		}
	}

	.footer .wrapper.two .item:first-of-type {
		margin-left: 0;
	}
	@media only screen and (max-width: 559px) {

		.footer .wrapper.two .item:first-of-type {
			margin: 0;
			display: block;
		}
	}

	.footer .wrapper.two .item:last-of-type {
		margin-right: 0;
	}
	@media only screen and (max-width: 559px) {

		.footer .wrapper.two .item:last-of-type {
			margin: 0 0 0 3em;
		}
	}

	.footer .wrapper.two .text {
		font-size: 1.4em;
		line-height: 2.142857em;
	}

	/* Intro */
	.intro .wrapper.one {
		margin-bottom: 11%;
		padding-bottom: 58.4375%;
	}
	@media only screen and (max-width: 1919px) {

		.intro .wrapper.one {
			margin-bottom: 16%;
		}
	}
	@media only screen and (max-width: 1599px) {

		.intro .wrapper.one {
			margin-bottom: 8%;
		}
	}
	@media only screen and (max-width: 1279px) {

		.intro .wrapper.one {
			margin-bottom: 5%;
		}
	}

	.intro .wrapper.one .content {
		bottom: 0;
		color: #fff;
		left: 19.5%;
		opacity: 0;
		top: 9em;
		width: 59.5em;
		z-index: 10;
		max-width: 75%;
	}
	@media only screen and (max-width: 1599px) {

		.intro .wrapper.one .content {
			top: 10em;
		}
	}
	@media only screen and (max-width: 1279px) {

		.intro .wrapper.one .content {
			top: 5em;
		}
	}

	.intro .wrapper.one .title.one {
		font-size: 9.2em;
		line-height: 1.125em;
		margin-bottom: 0.2em;
		font-weight: bold;
	}
	@media only screen and (max-width: 1023px) {

		.intro .wrapper.one .title.one {
			font-size: 7.8em;
		}
	}
	@media only screen and (max-width: 719px) {

		.intro .wrapper.one .title.one {
			font-size: 7.1em;
		}
	}
	@media only screen and (max-width: 559px) {

		.intro .wrapper.one .title.one {
			font-size: 5em;
		}
	}

	.intro .wrapper.one .title.two {
		bottom: 0;
		font-size: 1.8em;
		left: 0;
		line-height: 1.666667em;
		position: absolute;
	}

	.intro .wrapper.one .text {
		font-size: 3em;
		line-height: 1.7em;
	}
	@media only screen and (max-width: 559px) {

		.intro .wrapper.one .text {
			font-size: 2em;
		}
	}

	.intro .wrapper.one .brick.turquoise {
		opacity: 0;
	}

	.intro .wrapper.one .brick.one {
		height: 72.3em;
		width: 100%;
		z-index: 5;
	}
	@media only screen and (max-width: 559px) {

		.intro .wrapper.one .brick.one {
			height: 52.3em;
		}
	}

	.intro .wrapper.one .brick.two {
		left: 20%;
		top: 72.3em;
	}

	.intro .wrapper.one .brick.three {
		right: 0;
		top: 72.3em;
	}

	.intro .wrapper.one .brick.four {
		right: -20em;
		top: 32.3em;
	}

	.intro .wrapper.one .brick.five {
		left: -20em;
		top: 52.3em;
	}

	.intro .wrapper.one .brick.six {
		right: -20em;
		top: 52.3em;
	}

	.intro .wrapper.one .brick.seven,
	.intro .wrapper.one .brick.eight {
		right: -20em;
		top: 72.3em;
	}

	.intro .wrapper.one .brick.nine,
	.intro .wrapper.one .brick.ten,
	.intro .wrapper.one .brick.eleven,
	.intro .wrapper.one .brick.twelve {
		right: -20em;
		top: 92.3em;
	}

	.intro .wrapper.two .content {
		margin: 0 auto;
		padding: 13em 0 16.5em;
		text-align: center;
		width: 56em;
		max-width: 100%;
	}

	.intro .wrapper.two .title span {
		display: inline-block;
		width: 100%;
	}

	.intro .wrapper.two .title span.one {
		color: #48418e;
		font-size: 4em;
		line-height: 1.375em;
		margin-bottom: 7.25em;
	}
	@media only screen and (max-width: 1023px) {

		.intro .wrapper.two .title span.one {
			margin-bottom: 4.25em;
		}
	}

	.intro .wrapper.two .title span.two {
		color: #00b6bf;
		font-size: 3em;
		line-height: 1.5em;
	}

	.intro .wrapper.two .image {
		position: absolute;
		top: 45%;
	}

	.intro .wrapper.three {
		background-color: #d75790;
	}

	.intro .wrapper.three .content {
		color: #fff;
		padding: 8em 0 8.7em;
		text-align: center;
	}

	.intro .wrapper.three .content h2 {
		font-size: 4em;
		line-height: 1.375em;
		margin-bottom: 2.2em;
	}

	.intro .wrapper.three .col {
		display: inline-block;
		vertical-align: top;
		width: 45em;
	}
	@media only screen and (min-width: 920px) {

		.intro .wrapper.three .col {
			max-width: 33%;
		}
	}

	.intro .wrapper.three .col .title {
		font-size: 8em;
		line-height: 1.2em;
		margin-bottom: 0.375em;
	}

	.intro .wrapper.three .col .text {
		font-size: 2.4em;
		line-height: 1.5em;
		margin: 0 auto;
		max-width: 80%;
	}

	.intro .wrapper.three .col>.image {
		height: 27.7em;
	}
	@media only screen and (max-width: 1023px) {

		.intro .wrapper.three .col>.image {
			height: 23.7em;
		}
	}

	.intro .wrapper.three .col .image img {
		display: inline-block;
		margin-left: auto;
		margin-right: auto;
	}

	.intro .wrapper.three .col.one .image img {
		margin-top: 2.9em;
		width: 62.222222%;
	}

	.intro .wrapper.three .col.two .image img {
		margin-top: 3.3em;
		width: 67.777778%;
	}

	.intro .wrapper.three .col.three .image img {
		margin-top: 2.4em;
		width: 46.666667%;
	}

	.intro .wrapper.four {
		padding-bottom: 46.875%;
	}
	@media only screen and (max-width: 1023px) {

		.intro .wrapper.four {
			padding-bottom: 46.875%;
		}
	}
	@media only screen and (max-width: 719px) {

		.intro .wrapper.four {
			height: auto;
			padding-bottom: 1em;
		}
	}

	.intro .wrapper.four .video {
		bottom: 0;
		left: 0;
		right: 0;
		top: 0;
		line-height: 0;
	}
	@media only screen and (min-width: 720px) {

		.intro .wrapper.four .video {
			position: absolute;
		}
	}

	.intro .wrapper.four .video video {
		width: 100%;
	}

	.intro .wrapper.four .content {
		background-color: #d75790;
		-moz-box-shadow: 1em 1em #7c2d79;
		-webkit-box-shadow: 1em 1em #7c2d79;
		box-shadow: 1em 1em #7c2d79;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		color: #fff;
		padding: 4em 4.9em 5.5em 4.1em;
		right: 13.802083%;
		top: 29.375%;
		width: 60em;
	}
	@media only screen and (max-width: 1599px) {

		.intro .wrapper.four .content {
			right: 8%;
		}
	}
	@media only screen and (min-width: 720px) and (max-width: 919px) {

		.intro .wrapper.four .content {
			width: 40em;
			top: 20%;
		}
	}
	@media only screen and (max-width: 719px) {

		.intro .wrapper.four .content {
			display: block;
			position: initial;
			width: 99%;
		}
	}

	.intro .wrapper.four .title {
		font-size: 3em;
		line-height: 1.5em;
	}
	@media only screen and (max-width: 1023px) {

		.intro .wrapper.four .title {
			font-size: 2.5em;
		}
	}
	@media only screen and (max-width: 919px) {

		.intro .wrapper.four .title {
			font-size: 2em;
		}
	}

	/* Benefits */
	.benefits .wrapper.one {
		padding-bottom: 900px;
	}
	@media only screen and (max-width: 919px) {

		.benefits .wrapper.one {
			padding-bottom: 0;
			height: auto;
		}

		.benefits .wrapper.one h2 {
			text-align: center;
		}
	}

	.benefits .wrapper.one .map {
		background: url("../img/uk-north-map.png") no-repeat #e5e4df;
		max-width: 1570px;
		height: 900px;
	}
	@media only screen and (max-width: 1279px) {

		.benefits .wrapper.one .map {
			position: absolute;
			top: 0;
			left: -28em;
			right: 0;
			bottom: 0;
		}
	}
	@media only screen and (max-width: 919px) {

		.benefits .wrapper.one .map {
			left: -17em;
		}
	}
	@media only screen and (max-width: 719px) {

		.benefits .wrapper.one .map {
			left: -37em;
		}
	}

	.benefits .wrapper.one .map .image {
		left: 301px;
		position: absolute;
		top: 260px;
	}
	@media only screen and (max-width: 919px) {

		.benefits .wrapper.one .map {
			display: none;
		}
	}

	.benefits .wrapper.one .map-mobile {
		display: none;
		margin-bottom: 2em;
	}
	@media only screen and (max-width: 919px) {

		.benefits .wrapper.one .map-mobile {
			display: block;
		}
	}

	.benefits .wrapper.one .city {
		position: absolute;
	}

	.benefits .wrapper.one .city .pulse {
		left: 50%;
		opacity: 0.1;
		position: absolute;
		top: 50%;
		-ms-transform: translateX(-50%) translateY(-50%);
		-webkit-transform: translateX(-50%) translateY(-50%);
		transform: translateX(-50%) translateY(-50%);
	}

	.benefits .wrapper.one .city .pulse:after {
		animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
		background-color: #fff;
		border-radius: 50%;
		-moz-box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
		-webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
		box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
		content: "";
		display: block;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}

	.benefits .wrapper.one .city .pulse.one {
		height: 90px;
		width: 90px;
	}

	.benefits .wrapper.one .city .pulse.two {
		height: 70px;
		width: 70px;
	}

	.benefits .wrapper.one .city .pulse.three {
		height: 50px;
		width: 50px;
	}

	.benefits .wrapper.one .city .marker {
		border-radius: 50%;
		border: 4px solid #fff;
		height: 22px;
		left: 50%;
		position: absolute;
		top: 50%;
		-ms-transform: translateX(-50%) translateY(-50%);
		-webkit-transform: translateX(-50%) translateY(-50%);
		transform: translateX(-50%) translateY(-50%);
		width: 22px;
	}

	.benefits .wrapper.one .city .title {
		color: #fff;
		font-size: 16px;
		position: absolute;
		text-align: center;
		text-transform: uppercase;
	}

	.benefits .wrapper.one .city.newcastle {
		left: 550px;
		top: 265px;
	}

	.benefits .wrapper.one .city.newcastle .marker {
		background-color: #64b554;
	}

	.benefits .wrapper.one .city.newcastle .title {
		left: -117px;
		top: -12px;
	}

	.benefits .wrapper.one .city.liverpool {
		left: 307px;
		top: 703px;
	}

	.benefits .wrapper.one .city.liverpool .marker {
		background-color: #7c2d79;
	}

	.benefits .wrapper.one .city.liverpool .title {
		left: -11px;
		top: -39px;
	}

	.benefits .wrapper.one .city.manairport {
		left: 402px;
		top: 728px;
	}

	.benefits .wrapper.one .city.manairport .marker {
		background-color: #c2cf32;
	}

	.benefits .wrapper.one .city.manairport .title {
		left: -79px;
		top: 25px;
	}

	.benefits .wrapper.one .city.manchester {
		left: 428px;
		top: 687px;
	}

	.benefits .wrapper.one .city.manchester .marker {
		background-color: #c2cf32;
	}

	.benefits .wrapper.one .city.manchester .title {
		left: -84px;
		top: -48px;
	}

	.benefits .wrapper.one .city.leeds {
		left: 557px;
		top: 649px;
	}

	.benefits .wrapper.one .city.leeds .marker {
		background-color: #d75790;
	}

	.benefits .wrapper.one .city.leeds .title {
		left: -69px;
		top: -30px;
	}

	.benefits .wrapper.one .city.sheffield {
		left: 577px;
		top: 747px;
	}

	.benefits .wrapper.one .city.sheffield .marker {
		background-color: #00b6bf;
	}

	.benefits .wrapper.one .city.sheffield .title {
		left: 23px;
		top: 14px;
	}

	.benefits .wrapper.one .city.hull {
		left: 738px;
		top: 634px;
	}

	.benefits .wrapper.one .city.hull .marker {
		background-color: #171249;
	}

	.benefits .wrapper.one .city.hull .title {
		left: -56px;
		top: -35px;
	}

	.benefits .wrapper.one .content {
		bottom: 0;
		right: 25.5em;
		top: 70px;
	}
	@media only screen and (max-width: 1919px) {

		.benefits .wrapper.one .content {
			right: 15em;
		}
	}
	@media only screen and (max-width: 1599px) {

		.benefits .wrapper.one .content {
			right: 5em;
			top: 4em;
		}
	}
	@media only screen and (max-width: 919px) {

		.benefits .wrapper.one .content {
			position: relative;
			display: block;
			right: 0;
			top: 0;
		}
	}

	.benefits .wrapper.one .content .title {
		color: #00b6bf;
		font-size: 4em;
		line-height: 1.125em;
		margin-bottom: 1.625em;
	}
	@media only screen and (max-width: 1599px) {

		.benefits .wrapper.one .content .title {
			margin-bottom: 0.8em;
		}
	}

	.benefits .wrapper.one .blocks {
		position: relative;
		width: 69em;
	}
	@media only screen and (max-width: 919px) {

		.benefits .wrapper.one .blocks {
			min-height: 420px;
			position: relative;
			width: 100%;
		}
	}
	@media only screen and (max-width: 559px) {

		.benefits .wrapper.one .blocks {
			min-height: 840px;
		}
	}

	.benefits .wrapper.one .block {
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		color: #fff;
		display: inline-block;
		padding: 3em;
		vertical-align: top;
		width: 50%;
		left: 200%;
	}
	@media only screen and (max-width: 1599px) {

		.benefits .wrapper.one .block {
			padding: 2em;
		}
	}
	@media only screen and (max-width: 719px) {

		.benefits .wrapper.one .block {
			min-height: 155px;
		}
	}
	@media only screen and (max-width: 559px) {

		.benefits .wrapper.one .block {
			min-height: 140px;
			height: 140px !important;
			width: 100%;
		}
	}

	.benefits .wrapper.one .block.one,
	.benefits .wrapper.one .block.two {
		height: 24em;
		top: 0;
	}
	@media only screen and (max-width: 1599px) {

		.benefits .wrapper.one .block.one,
		.benefits .wrapper.one .block.two {
			height: 20em;
		}
	}

	.benefits .wrapper.one .block.one {
		background-color: #c2cf32;
	}

	.benefits .wrapper.one .block.one.animate {
		left: 0;
	}

	.benefits .wrapper.one .block.two {
		background-color: #48418e;
	}

	.benefits .wrapper.one .block.two.animate {
		left: 50%;
	}
	@media only screen and (max-width: 559px) {

		.benefits .wrapper.one .block.two {
			top: 140px;
		}
	}

	.benefits .wrapper.one .block.three,
	.benefits .wrapper.one .block.four {
		height: 27em;
		top: 24em;
	}
	@media only screen and (max-width: 1599px) {

		.benefits .wrapper.one .block.three,
		.benefits .wrapper.one .block.four {
			height: 22em;
			top: 20em;
		}
	}

	.benefits .wrapper.one .block.three {
		background-color: #00b6bf;
	}

	.benefits .wrapper.one .block.three.animate {
		left: 0;
	}
	@media only screen and (max-width: 559px) {

		.benefits .wrapper.one .block.three {
			top: 280px;
		}
	}

	.benefits .wrapper.one .block.four {
		background-color: #e8e14b;
		color: #48418e;
	}

	.benefits .wrapper.one .block.four.animate {
		left: 50%;
	}
	@media only screen and (max-width: 559px) {

		.benefits .wrapper.one .block.four {
			top: 420px;
		}
	}

	.benefits .wrapper.one .block.five,
	.benefits .wrapper.one .block.six {
		height: 21em;
		top: 51em;
	}
	@media only screen and (max-width: 1599px) {

		.benefits .wrapper.one .block.five,
		.benefits .wrapper.one .block.six {
			height: 18em;
			top: 42em;
		}
	}

	.benefits .wrapper.one .block.five {
		background-color: #568280;
	}

	.benefits .wrapper.one .block.five.animate {
		left: 0;
	}
	@media only screen and (max-width: 559px) {

		.benefits .wrapper.one .block.five {
			top: 560px;
		}
	}

	.benefits .wrapper.one .block.six {
		background-color: #d75790;
	}

	.benefits .wrapper.one .block.six.animate {
		left: 50%;
	}
	@media only screen and (max-width: 559px) {

		.benefits .wrapper.one .block.six {
			top: 700px;
		}
	}
	@media only screen and (max-width: 559px) {

		.benefits .wrapper.one .block.animate {
			left: 0 !important;
		}
	}

	.benefits .wrapper.one .block .images {
		height: 7em;
		margin-bottom: 2em;
	}

	.benefits .wrapper.one .block .image {
		display: inline-block;
		margin-right: 2%;
		max-height: 100%;
		vertical-align: middle;
	}

	.benefits .wrapper.one .block .text {
		font-size: 1.8em;
		line-height: 1.666667em;
	}
	@media only screen and (max-width: 1599px) {

		.benefits .wrapper.one .block .text {
			font-size: 1.6em;
			line-height: 1.2em;
		}
	}
	@media only screen and (max-width: 719px) {

		.benefits .wrapper.one .block .text {
			font-size: 1.8em;
			line-height: 1.35em;
		}
	}
	@media only screen and (max-width: 559px) {

		.benefits .wrapper.one .block .text {
			font-size: 2em;
			line-height: 1.35em;
		}
	}

	.benefits .wrapper.two {
		background-color: #171249;
		padding: 7em;
	}

	.benefits .wrapper.two .content {
		color: #fff;
		margin: 0 auto;
		text-align: center;
		width: 93em;
	}

	.benefits .wrapper.two .text {
		font-size: 1.8em;
		line-height: 1.666667em;
	}

	/* Transforming */
	.transforming .wrapper.one {
		padding-bottom: 46.875%;
	}
	@media only screen and (max-width: 559px) {

		.transforming .wrapper.one {
			height: auto;
			padding-top: 46.75%;
			padding-bottom: 0;
		}

		.transforming .wrapper.one .brick {
			display: none;
		}
	}

	.transforming .wrapper.one .content {
		background-color: #00b6bf;
		background-color: rgba(0, 182, 191, 0.9);
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		color: #fff;
		padding: 3.2em 4em 5.3em;
		top: 25%;
		width: 69em;
		z-index: 5;
		left: -100%;
	}
	@media only screen and (max-width: 1279px) {

		.transforming .wrapper.one .content {
			top: 17%;
		}
	}
	@media only screen and (max-width: 919px) {

		.transforming .wrapper.one .content {
			top: 10%;
		}
	}
	@media only screen and (max-width: 719px) {

		.transforming .wrapper.one .content {
			display: block;
			width: 85%;
		}
	}
	@media only screen and (max-width: 559px) {

		.transforming .wrapper.one .content {
			position: initial;
			width: 100%;
		}
	}

	.transforming .wrapper.one .content.animate {
		left: 19.53125%;
	}
	@media only screen and (max-width: 719px) {

		.transforming .wrapper.one .content.animate {
			left: 9.53125%;
		}
	}

	.transforming .wrapper.one .title.one {
		font-size: 4em;
		line-height: 1.375em;
		margin-bottom: 0.5em;
	}
	@media only screen and (max-width: 1023px) {

		.transforming .wrapper.one .title.one {
			font-size: 3.5em;
		}
	}

	.transforming .wrapper.one .title.two {
		font-size: 3em;
		line-height: 1.5em;
	}
	@media only screen and (max-width: 1023px) {

		.transforming .wrapper.one .title.two {
			font-size: 2.5em;
		}
	}

	.transforming .wrapper.one .title.three {
		bottom: 1.611111em;
		color: #fff;
		font-size: 1.8em;
		left: 19.5313%;
		line-height: 1.666667em;
		position: absolute;
	}
	@media only screen and (max-width: 559px) {

		.transforming .wrapper.one .title.three {
			top: 2%;
		}
	}

	.transforming .wrapper.one .brick.mustard {
		right: -20em;
		top: 0;
	}

	.transforming .wrapper.one .brick.mustard.animate {
		right: 0;
	}

	.transforming .wrapper.one .brick.white {
		right: -20em;
		opacity: 0;
		top: 20em;
	}

	.transforming .wrapper.one .brick.white.animate {
		opacity: 1;
		right: 20em;
	}

	.transforming .wrapper.one .brick.purple {
		right: -60em;
		top: 20em;
	}

	.transforming .wrapper.one .brick.purple.animate {
		right: 0;
	}

	.transforming .wrapper.one .brick.turquoise {
		opacity: 0;
		right: -20em;
		top: 40em;
	}

	.transforming .wrapper.one .brick.turquoise.animate {
		opacity: 1;
		right: 40em;
	}

	.transforming .wrapper.one .brick.yellow {
		right: -80em;
		top: 40em;
	}

	.transforming .wrapper.one .brick.yellow.animate {
		right: 0;
	}
	@media only screen and (max-width: 719px) {

		.transforming .wrapper.two {
			padding: 12em 0 52.3em;
		}
	}

	.transforming .wrapper.two .content {
		right: 150%;
	}

	.transforming .wrapper.two .content.one {
		-moz-box-shadow: 1em 1em #d75790;
		-webkit-box-shadow: 1em 1em #d75790;
		box-shadow: 1em 1em #d75790;
		width: 59.2em;
	}

	.transforming .wrapper.two .content.one .title {
		bottom: 1em;
		color: #fff;
		font-size: 1.8em;
		left: 1.111111em;
		line-height: 1.666667em;
		position: absolute;
	}

	.transforming .wrapper.two .content.one .image {
		width: 100%;
	}

	.transforming .wrapper.two .content.one.animate {
		right: 49.635417%;
	}
	@media only screen and (max-width: 919px) {

		.transforming .wrapper.two .content.one {
			width: 49.2em;
		}
	}

	.transforming .wrapper.two .content.two {
		width: 48em;
	}

	.transforming .wrapper.two .content.two .title.one {
		color: #48418e;
		font-size: 4em;
		line-height: 1.375em;
		margin-bottom: 0.5em;
	}

	.transforming .wrapper.two .content.two .title.two {
		color: #00b6bf;
		font-size: 3em;
		line-height: 1.5em;
	}

	.transforming .wrapper.two .content.two.animate {
		left: 57.03125%;
	}
	@media only screen and (max-width: 719px) {

		.transforming .wrapper.two .content {
			display: block;
			position: initial;
			width: 90% !important;
			margin: 5%;
		}
	}
	@media only screen and (max-width: 719px) {

		.transforming .wrapper.two {
			padding-bottom: 12em;
		}
	}

	.transforming .wrapper.three {
		text-align: center;
	}

	.transforming .wrapper.three .content {
		display: inline-block;
	}

	.transforming .wrapper.three .content .text {
		color: #48418e;
		font-size: 2.4em;
		line-height: 1.458333em;
	}

	.transforming .wrapper.three .content .text .title {
		display: inline-block;
		font-size: 3.333333em;
		line-height: 1.175em;
		margin-bottom: 0.375em;
	}

	.transforming .wrapper.three .content.one {
		margin-right: 7.8125%;
	}

	.transforming .wrapper.three .content.one .text .title {
		color: #c2cf32;
	}

	.transforming .wrapper.three .content.two {
		margin-left: 7.8125%;
	}

	.transforming .wrapper.three .content.two .text .title {
		color: #64b554;
	}
	@media only screen and (max-width: 719px) {

		.transforming .wrapper.four {
			padding-bottom: 22.4em;
		}
	}

	.transforming .wrapper.five {
		margin-bottom: 1em;
		padding-bottom: 63.9em;
		text-align: center;
		background-size: contain;
		background-position-x: center;
		background-position-y: bottom;
		background-repeat: no-repeat;
	}
	@media only screen and (max-width: 1023px) {

		.transforming .wrapper.five {
			padding-bottom: 43.9em;
		}
	}
	@media only screen and (max-width: 919px) {

		.transforming .wrapper.five {
			padding-bottom: 38.9em;
		}
	}
	@media only screen and (max-width: 719px) {

		.transforming .wrapper.five {
			padding-bottom: 28.9em;
		}
	}
	@media only screen and (max-width: 559px) {

		.transforming .wrapper.five {
			padding-bottom: 18.9em;
		}
	}

	.transforming .wrapper.five .content {
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		color: #fff;
		display: inline-block;
		position: relative;
		width: 117em;
	}

	.transforming .wrapper.five .box {
		background-color: #7c2d79;
		-moz-box-shadow: 1em 1em #e5e4df;
		-webkit-box-shadow: 1em 1em #e5e4df;
		box-shadow: 1em 1em #e5e4df;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		padding: 6.8em 5em 5.7em 7em;
		position: relative;
		width: 100%;
	}

	.transforming .wrapper.five .static {
		opacity: 0;
		position: initial;
	}

	.transforming .wrapper.five .static.animate {
		opacity: 1;
	}

	.transforming .wrapper.five .quote {
		margin: 0 0 4em;
		text-align: left;
	}

	.transforming .wrapper.five .quote .text {
		font-size: 3em;
		line-height: 1.333333em;
	}

	.transforming .wrapper.five .cite {
		display: block;
		font-size: 2.4em;
		font-style: normal;
		line-height: 1.458333em;
		text-align: right;
	}

	.transforming .wrapper.five .name {
		color: #e8e14b;
	}

	.transforming .wrapper.five .image {
		position: absolute;
		z-index: 5;
	}

	.transforming .wrapper.five .image.one {
		left: -8em;
		top: -6em;
	}

	.transforming .wrapper.five .image.two {
		bottom: -6em;
		right: -8em;
	}

	.transforming .wrapper.six {
		background-color: #48418e;
		text-align: center;
	}

	.transforming .wrapper.six .content {
		color: #fff;
		display: inline-block;
		padding: 10.5em 0;
		width: 100%;
	}

	.transforming .wrapper.six .title {
		font-size: 4em;
		line-height: 1.375em;
		margin-bottom: 2em;
	}

	.transforming .wrapper.six .col {
		display: inline-block;
		margin: 3em 2.1%;
		vertical-align: top;
	}
	@media only screen and (min-width: 560px) and (max-width: 719px) {

		.transforming .wrapper.six .col {
			width: 45%;
		}
	}
	@media only screen and (max-width: 559px) {

		.transforming .wrapper.six .col {
			display: block;
		}
	}

	.transforming .wrapper.six .image {
		height: 14em;
		margin: 0 auto 3em;
	}
	@media only screen and (max-width: 719px) {

		.transforming .wrapper.six .image {
			margin-bottom: 1em;
		}
	}

	.transforming .wrapper.six .text {
		font-size: 2.4em;
		line-height: 1.25em;
	}

	.future .wrapper {
		background-color: #00b6bf;
		height: 800px;
		overflow: hidden;
	}
	@media only screen and (max-width: 559px) {

		.future .wrapper {
			height: auto;
		}
	}

	.future .wrapper>.image {
		max-width: none;
		position: absolute;
		width: 4250px;
	}
	@media only screen and (max-width: 559px) {

		.future .wrapper>.image {
			position: initial;
			max-width: none;
			margin-left: -17em;
			margin-bottom: -10%;
			width: 160% !important;
			margin-top: 37em !important;
		}
	}

	.future .wrapper>.image.zoomout {
		right: 100px;
		top: -260px;
		-moz-transition: all 2s ease-in-out;
		-webkit-transition: all 2s ease-in-out;
		transition: all 2s ease-in-out;
		width: 1047px;
	}
	@media only screen and (max-width: 1919px) {

		.future .wrapper>.image.zoomout {
			right: 40px;
		}
	}
	@media only screen and (max-width: 1599px) {

		.future .wrapper>.image.zoomout {
			right: 60px;
			top: -240px;
		}
	}
	@media only screen and (max-width: 1279px) {

		.future .wrapper>.image.zoomout {
			right: -230px;
		}
	}

	.future .content {
		top: 15.3em;
		width: 54em;
		left: -100%;
		opacity: 0;
	}

	.future .content.animate {
		left: 14.84375%;
		opacity: 1;
	}
	@media only screen and (max-width: 919px) {

		.future .content.animate {
			left: 7%;
		}
	}
	@media only screen and (max-width: 719px) {

		.future .content.animate {
			left: 0;
		}
	}
	@media only screen and (max-width: 559px) {

		.future .content {
			width: 100%;
			z-index: 10;
			top: 0;
			height: 400px;
		}
	}

	.future .content .image {
		left: -3em;
		position: absolute;
		top: -4em;
	}
	@media only screen and (max-width: 559px) {

		.future .content .image {
			left: -1em;
			top: -1em;
		}
	}

	.future .main {
		color: #fff;
	}
	@media only screen and (max-width: 1599px) {

		.future .main {
			background-color: rgba(72, 65, 142, 0.75);
			padding: 3em;
		}
	}

	.future .main .text {
		font-size: 1.8em;
		line-height: 1.666667em;
		margin-bottom: 3.333333em;
	}
	@media only screen and (max-width: 559px) {

		.future .main .text {
			margin: 0;
		}
	}
	@media only screen and (max-width: 559px) {

		.future .main {
			min-height: 40%;
		}
	}

	.future .title {
		font-size: 3em;
		line-height: 1.5em;
		margin-bottom: 0.666667em;
	}

	.future .legend {
		background-color: #fff;
		border: 0.4em solid #00b6bf;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		display: block;
		color: #48418e;
		padding: 1.4em 2em;
	}
	@media only screen and (max-width: 559px) {

		.future .legend {
			min-height: 50%;
		}
	}

	.future .legend .row {
		display: inline-block;
		margin: 1em 0;
		width: 100%;
	}

	.future .legend .item {
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		display: inline-block;
		vertical-align: middle;
		width: 50%;
	}

	.future .legend .item .text {
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		display: inline-block;
		font-size: 1.4em;
		padding: 0 0.357143em;
		vertical-align: middle;
		width: calc(100% - 3em);
	}

	.future .legend .item .text.blue {
		background-color: #48418e;
		color: #fff;
		padding: 0.642857em 0.785714em;
	}

	.future .legend .symbol {
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		display: inline-block;
		padding-right: 0.5em;
		vertical-align: middle;
		width: 2.5em;
	}

	.future .legend .line {
		display: inline-block;
		height: 0.6em;
		width: 2em;
	}

	.future .legend .line.purple {
		background-color: #7c2d79;
	}

	.future .legend .line.yellow {
		background-color: #c2cf32;
	}

	.future .legend .line.green {
		background-color: #64b554;
	}

	.future .legend .line.teal {
		background-color: #568280;
	}

	.future .legend .line.black {
		background-color: #171249;
	}

	.future .legend .line.grey {
		background-color: #9b9b9b;
	}

	.future .legend .circle {
		border-radius: 50%;
		border-style: solid;
		border-width: 0.3em;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		display: inline-block;
		height: 2em;
		width: 2em;
	}

	.future .legend .circle.blue {
		background-color: #fff;
		border-color: #48418e;
	}

	.future .legend .circle.turquoise {
		background-color: #00b6bf;
		border-color: #00b6bf;
	}

	.future .junctions {
		background-color: #fff;
		border: 0.4em solid #e5e4df;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		padding: 1.4em 2.4em 2.2em 1.6em;
		width: 28em;
		right: -100%;
		opacity: 0;
		top: 6em;
	}
	@media only screen and (max-width: 559px) {

		.future .junctions {
			top: auto;
			bottom: 0;
			width: 100%;
		}
	}

	.future .junctions.animate {
		right: 3.125%;
		opacity: 1;
	}
	@media only screen and (max-width: 719px) {

		.future .junctions.animate {
			right: 0;
		}
	}

	.future .junctions .title {
		color: #48418e;
		font-size: 1.4em;
		line-height: 1.4em;
	}

	.future .junctions .row {
		margin-top: 2em;
	}
	@media only screen and (max-width: 559px) {

		.future .junctions .row {
			width: 49%;
			display: inline-block;
		}
	}

	.future .junctions .image {
		display: inline-block;
		vertical-align: middle;
		width: 10.416667%;
	}

	.future .junctions .text {
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		color: #48418e;
		display: inline-block;
		font-size: 1.3em;
		line-height: 1.454545em;
		padding: 0 1em;
		vertical-align: middle;
		width: 89.583333%;
	}

	.expectations .wrapper.one {
		margin: 0 auto;
		width: 117em;
	}
	@media only screen and (max-width: 919px) {

		.expectations .wrapper.one {
			padding: 0 2.5%;
			width: 95%;
		}
	}
	@media only screen and (max-width: 559px) {

		.expectations .wrapper.one {
			padding: 0 5%;
			width: 90%;
		}
	}

	.expectations .wrapper.one .content {
		padding-top: 12em;
	}

	.expectations .wrapper.one .content.one .title.one {
		color: #48418e;
		font-size: 4em;
		line-height: 1.375em;
		margin-bottom: 0.75em;
	}

	.expectations .wrapper.one .content.one .title.two {
		color: #00b6bf;
		font-size: 3em;
		line-height: 1.5em;
		margin-bottom: 0.75em;
	}

	.expectations .wrapper.one .content.two .col.image .image,
	.expectations .wrapper.one .content.four .col.image .image {
		width: 72.222222%;
	}

	.expectations .wrapper.one .content.three .col.image {
		text-align: right;
	}

	.expectations .wrapper.one .content.three .col.image .image {
		display: inline-block;
		width: 86.111111%;
	}

	.expectations .wrapper.one .content.three .text .regular {
		display: inline-block;
		padding-right: 15%;
	}

	.expectations .wrapper.one .content.five {
		padding: 12.5em 0 12em;
	}

	.expectations .wrapper.one .content.five .text {
		color: #434343;
		font-size: 1.8em;
		line-height: 1.666667em;
	}

	.expectations .wrapper.one .col {
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		display: inline-block;
		vertical-align: middle;
	}

	.expectations .wrapper.one .col.main {
		width: 70%;
	}

	.expectations .wrapper.one .col.image {
		width: 30%;
	}

	.expectations .wrapper.one .text .title {
		color: #00b6bf;
	}

	.expectations .wrapper.one .text .title.one {
		font-size: 6em;
		line-height: 1.166667em;
	}
	@media only screen and (max-width: 559px) {

		.expectations .wrapper.one .text .title.one {
			font-size: 4em;
		}
	}

	.expectations .wrapper.one .text .title.two {
		display: inline-block;
		font-size: 12em;
		line-height: 1.175em;
	}
	@media only screen and (max-width: 559px) {

		.expectations .wrapper.one .text .title.two {
			font-size: 7em;
		}
	}

	.expectations .wrapper.one .text .regular {
		color: #48418e;
		font-size: 2.4em;
		line-height: 1.458333em;
	}

	.nextsteps {
		position: relative;
	}

	.nextsteps .wrapper.one {
		background: url("../img/all-cityscapes.png") bottom center no-repeat #48418e;
		padding-bottom: 80em;
	}

	.nextsteps .wrapper.one .content {
		color: #fff;
		top: 29.375%;
		width: 80.9em;
		left: -100%;
		opacity: 0;
	}
	@media only screen and (max-width: 919px) {

		.nextsteps .wrapper.one .content {
			width: 60em;
		}
	}

	.nextsteps .wrapper.one .content.animate {
		left: 19.583333%;
		opacity: 1;
	}
	@media only screen and (max-width: 559px) {

		.nextsteps .wrapper.one .content {
			width: 75%;
			top: 20%;
		}

		.nextsteps .wrapper.one .content.animate {
			left: 13%;
		}
	}

	.nextsteps .wrapper.one .title {
		font-size: 4em;
		line-height: 1.375em;
	}

	.nextsteps .wrapper.one .image {
		left: -3em;
		position: absolute;
		top: -5em;
	}

	.nextsteps .wrapper.two {
		padding-bottom: 41.666667%;
		z-index: 2;
	}

	.nextsteps .wrapper.two .overlay {
		background-color: #00b6bf;
		bottom: 0;
		left: 0;
		opacity: 0.6;
		position: absolute;
		right: 0;
		top: 0;
	}

	.nextsteps .wrapper.two .content {
		bottom: 0;
		color: #fff;
		left: 17.760417%;
		top: 0;
		width: 39em;
	}
	@media only screen and (max-width: 919px) {

		.nextsteps .wrapper.two .content {
			width: 59em;
		}
	}
	@media only screen and (max-width: 719px) {

		.nextsteps .wrapper.two .content {
			width: 56em;
		}
	}
	@media only screen and (max-width: 559px) {

		.nextsteps .wrapper.two .content {
			width: 39em;
			left: 10%;
		}
	}

	.nextsteps .wrapper.two .title.one {
		font-size: 4em;
		line-height: 1.375em;
		margin: 6.7em 0 0.5em;
	}
	@media only screen and (max-width: 1919px) {

		.nextsteps .wrapper.two .title.one {
			margin-top: 6em;
		}
	}
	@media only screen and (max-width: 1599px) {

		.nextsteps .wrapper.two .title.one {
			margin-top: 5em;
		}
	}
	@media only screen and (max-width: 1279px) {

		.nextsteps .wrapper.two .title.one {
			margin-top: 4em;
		}
	}
	@media only screen and (max-width: 1023px) {

		.nextsteps .wrapper.two .title.one {
			margin-top: 3em;
		}
	}
	@media only screen and (max-width: 919px) {

		.nextsteps .wrapper.two .title.one {
			margin-top: 2.5em;
		}
	}
	@media only screen and (max-width: 559px) {

		.nextsteps .wrapper.two .title.one {
			font-size: 2.5em;
		}
	}

	.nextsteps .wrapper.two .title.two {
		font-size: 3em;
		line-height: 1.5em;
	}
	@media only screen and (max-width: 559px) {

		.nextsteps .wrapper.two .title.two {
			font-size: 2em;
		}
	}

	.nextsteps .wrapper.two .title.three {
		bottom: 7.25%;
		font-size: 1.8em;
		left: 0;
		line-height: 1.666667em;
		position: absolute;
	}

	.intro .wrapper.one {
		background-image: url("/tftn_north_statics/css/general/img/hero.jpg");
	}

	.intro .wrapper.one .content {
		width: 70em;
	}

	.transforming .wrapper.one {
		background-image: url("/tftn_north_statics/css/general/img/transforming.jpg");
	}

	.transforming .wrapper.two {
		padding: 12em 0 62.3em;
	}
	@media only screen and (max-width: 719px) {

		.transforming .wrapper.two {
			padding-bottom: 12em;
		}
	}

	.transforming .wrapper.two .content.one .image {
		max-height: none;
	}

	.transforming .wrapper.two .content.two {
		width: 50em;
	}
	@media only screen and (max-width: 919px) {

		.transforming .wrapper.two .content.two {
			width: 40em;
		}

		.transforming .wrapper.two .content.two .title.two {
			font-size: 2.5em;
		}
	}

	.transforming .wrapper.four {
		padding-bottom: 32.4em;
	}

	.transforming .wrapper.five {
		background-image: url("/tftn_north_statics/css/general/img/manchester-cityscape.png");
	}

	.future .wrapper>.image {
		right: -1620px;
		top: -3030px;
	}
	@media only screen and (max-width: 1919px) {

		.future .wrapper>.image {
			right: -1680px;
		}
	}
	@media only screen and (max-width: 1599px) {

		.future .wrapper>.image {
			right: -1930px;
		}
	}
	@media only screen and (max-width: 1279px) {

		.future .wrapper>.image {
			right: -1980px;
		}
	}

	.expectations .wrapper.one .content.two .title.two {
		margin-bottom: 0.241667em;
	}

	.expectations .wrapper.one .content.three .title.one {
		display: inline-block;
		margin-bottom: 0.5em;
	}

	.expectations .wrapper.one .content.four .title.one {
		display: inline-block;
		margin-bottom: 0.733333em;
	}

	.nextsteps .wrapper.two {
		background-image: url("/tftn_north_statics/css/general/img/airport.jpg");
	}
	margin-bottom: 0.241667em;
}

.expectations .wrapper.one .content.three .title.one {
	display: inline-block;
	margin-bottom: 0.5em;
}

.expectations .wrapper.one .content.four .title.one {
	display: inline-block;
	margin-bottom: 0.733333em;
}

.nextsteps .wrapper.two {
	background-image: url("/tftn_north_statics/css/general/img/airport.jpg");
}