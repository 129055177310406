.intro{

	.wrapper{

		&.two{
			padding: 0 2em;

			.content {
				padding: 9em 0 10.5em;
			}

			.title {

				span {

					&.one {
						margin-bottom: 5.25em;
					}
				}
			}

			.image {
				top: 40%
			}
		}
	}
}


/* BREAKPOINTS */

@media only screen and (max-width: map-get($bp, '719')) {

	.intro{

		.wrapper{

			&.two{

				.image {
					top: 46.5%
				}
			}
		}
	}
}