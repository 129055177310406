/* ORIGINAL STYLE OVERRIDES */

.intro {

	.wrapper {

		&.four {

			.content {
				background-color: $navy;
				-moz-box-shadow: 1em 1em $teal;
				-webkit-box-shadow: 1em 1em $teal;
				box-shadow: 1em 1em $teal;
			}
		}
	}
}