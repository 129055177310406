/* Visibly hidden: https://a11yproject.com/posts/how-to-hide-content/ */
.visually-hidden {
	position: absolute !important;
	overflow: hidden;
	clip: rect(1px 1px 1px 1px);

	/* IE6, IE7 */
	clip: rect(1px, 1px, 1px, 1px);
	width: 1px;
	height: 1px;
}