/* ORIGINAL STYLE OVERRIDES */

.nextsteps {

	.wrapper {

		&.one {
			background: url("../img/rebrand/next-steps/outline-left.svg") no-repeat left 49px bottom -3px, url("../img/rebrand/next-steps/outline-right.svg") no-repeat right top / contain;
			background-color: $navy;
			color: $white;
			padding: 11em 3em;

			.content {
				position: static;
				text-align: center;
				width: 95em;
				top: 28%;
				margin: 0 auto;

				&.animate {
					left: 50%;
				}
			}

			.title {
				font-size: 5em;
				line-height: 1.2em;
			}
		}

		&.two {

			.manchester & {
				background-image: url("../img/manchester/next-steps-image.jpg");
			}

			.liverpool & {
				background-image: url("../img/liverpool/next-steps-image.jpg");
			}

			.leeds & {
				background-image: url("../img/leeds/next-steps-image.jpg");
			}

			.hull & {
				background-image: url("../img/hull/next-steps-image.jpg");
			}

			.newcastle & {
				background-image: url("../img/newcastle/people_newcastle.jpg");
			}

			.sheffield & {
				background-image: url("../img/sheffield/next-steps-image.jpg");
			}

			background-repeat: no-repeat;
			background-position: center 0;
			background-size: cover;
			padding: 0;
			height: 800px;

			.content {
				background: url("../img/rebrand/next-steps/banner-shape.svg") no-repeat right center / cover;
				left: 0;
				width: 106.9em;
				box-sizing: border-box;
				color: $navy;
				padding: 0 25em 0 7.5em;
				display: flex;
				align-items: center;
			}

			.title {

				&.one {
					font-size: 5em;
					margin: 0 0 .3em;
				}

				&.two {
					font-size: 3em;
				}
			}
		}
	}
}

/* BREAKPOINTS */

@media only screen and (max-width: map-get($bp, '1919')) {

	.nextsteps {

		.wrapper {

			&.one {
				background-size: 386px auto, 280px auto;
			}

		}
	}

}

@media only screen and (max-width: map-get($bp, '1599')) {

	.nextsteps {

		.wrapper {

			&.one {
				background-size: 369px auto, 250px auto;
			}

			&.two {
				height: 666px;
			}
		}
	}

}


@media only screen and (max-width: map-get($bp, '1279')) {

	.nextsteps {

		.wrapper {

			&.one {
				background-size: 344px auto, 230px auto;
			}

			&.two {
				height: 533px;
			}
		}
	}

}

@media only screen and (max-width: map-get($bp, '719')) {

	.nextsteps {

		.wrapper {

			&.one {
				background-image: none;
			}

			&.two {
				padding-bottom: 58%;
				height: 0;

				.content {
					padding: 0 30em 0 13.5em
				}
			}
		}
	}
}

@media only screen and (max-width: map-get($bp, '559')) {

	.nextsteps {

		.wrapper {

			&.one {
				padding: 5em 3em;

				.title {
					font-size: 3em;
				}
			}

			&.two {
				//padding-bottom: 58%;
				//height:0;

				.content {
					padding: 3% 16% 3% 26%;
					font-size: 1.4vw;
					background-position: right bottom;
				}

				.title {

					&.one {
						line-height: normal;
					}
				}
			}
		}
	}
}
